.m-sectionTitle {
  background-clip: padding-box, border-box;
  background-color: $white;
  background-image: $section-border;
  background-origin: border-box;
  border: double 1.21px transparent;
  border-radius: rem(20);
  padding: rem(4);
  position: relative;

  &_title {
    left: 50%;
    position: absolute;
    top: rem(-32);
    transform: translateX(-50%);

    @include mobile-down {
      min-width: rem(286);
    }

    .a-button {
      text-transform: uppercase;

      @include tablet-down {
        min-width: rem(286);
        padding: rem(14) rem(8);
      }
    }
  }
}
