.t-prizeTable {
  $root: &;
  padding: rem(90) 0 rem(50);
  position: relative;
  width: 100vw;

  .o-table.o-table-secondary {
    background: none;
    padding: 0;
  }

  @include tablet-down {
    padding: rem(25) 0;
  }

  &-6 {
    #{$root}_list {
      margin-top: rem(32);
    }

    #{$root}_list_item {
      @include tablet-up {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: rem(8);
        padding-right: rem(8);
      }
    }
  }

  &_header {
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: rem(16) rem(24);
    width: 100%;
    z-index: $z-1;
  }

  &_logo {
    max-width: 18%;
    width: 100%;
  }

  &_clplogo {
    max-width: 10%;
    width: 100%;
  }

  &-double {
    #{$root}_list {
      @include tablet-up {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-8);
        margin-right: rem(-8);
      }

      .o-table {
        height: 100%;

        &_wrap {
          height: 100%;
        }
      }

      .o-table_header_cell,
      .o-table_body_cell {
        padding: rem(12) 0;

        .a-text {
          @include font-base(14, 18);

          @include break(1200px, 1500px) {
            @include font-base(12, 16);
          }
        }
      }

      .o-table_header_cell:nth-child(2) {
        @include break-min(1300px) {
          width: 12%;
        }
      }

      .o-table_header_cell {
        &:nth-child(1) {
          width: 10%;
        }

        &:last-child {
          width: 46%;
        }
      }
    }

    #{$root}_list_item {
      @include tablet-up {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: rem(8);
        padding-right: rem(8);
      }
    }
  }

  &-empty {
    #{$root}_wrap {
      @include adjust-flex(center, center);
    }

    .t-prizeTable_empty {
      margin-top: rem(20);
    }
  }

  &_background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-1;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &_wrap {
    height: 100%;
    position: relative;
    z-index: $z-2;

    .o-container-fullscreen {
      @include break-min(1400px) {
        max-width: 93%;
      }

      @include break-min(2000px) {
        max-width: 1900px;
      }
    }
  }

  &_top {
    position: relative;
    @include adjust-flex(center, center);

    &::before {
      background: transparent url('~assets/images/prizes/arrow.png') no-repeat center/100% auto;
      content: '';
      height: rem(51);
      left: 50%;
      position: absolute;
      top: rem(9);
      transform: translateX(-50%);
      width: rem(54);

      @include tablet-down {
        height: rem(16);
        top: rem(16);
        width: rem(30);
      }
    }

    &_item {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: rem(55);
      padding-right: rem(55);

      @include tablet-down {
        padding-left: rem(24);
        padding-right: rem(24);
      }

      &:first-child {
        @include adjust-flex(flex-end, center);
      }
    }
  }

  &_list {
    margin-top: rem(48);

    @include tablet-down {
      height: 68vh;
      margin-top: rem(20);
      overflow: auto;
      @include scroll-bars;
    }

    &_item {
      &:not(:first-child) {
        @include tablet-down {
          margin-top: rem(18);
        }
      }
    }

    .o-table_header_cell {
      &:nth-child(2) {
        width: 21%;
      }
    }

    .o-table_header_cell,
    .o-table_body_cell {
      .a-text {
        @include tablet-down {
          @include font-base(12, 18);
        }
      }
    }
  }

  &_label,
  &_value {
    display: inline-block;
    padding: rem(16) rem(20) rem(16);
    position: relative;

    img {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: $z-1;
    }

    .a-text {
      position: relative;
      z-index: $z-2;
    }
  }

  &_label {
    .a-text {
      display: inline-block;
      padding-top: rem(4);

      @include tablet-down {
        padding-top: 0;
      }
    }
  }
}