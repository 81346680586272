.o-prizeCollapse {
  $root: &;
  position: relative;

  &-active {
    #{$root}_title {
      background: $collpaseTabActive;
      color: $russet;
    }
  }

  &-collapse {
    #{$root}_title {
      &::before {
        transform: rotate(180deg);
      }
    }

    #{$root}_list {
      max-height: rem(413);
      opacity: 1;
      overflow: auto;
      visibility: visible;
    }
  }

  &-small {
    #{$root}_title {
      @include desktop-down {
        padding: 7px;
        @include font-base(11, 17);
      }

      &::before {
        display: none;
      }
    }
  }

  &-announce {
    #{$root}_title {
      @include font-base(20, 28);
      margin: 0 auto;
      max-width: rem(480);
      padding: rem(8) rem(32);
      text-align: center;
      white-space: normal;
      width: 100%;

      @include tablet-down {
        @include font-base(14, 20);
        padding: rem(15);
      }

      @include mobile-down {
        @include font-base(11, 16);
        width: calc(100% - 60px);
      }

      &_hand {
        position: absolute;
        right: rem(-48);
        top: rem(6);
        @include mobile-down {
          right: rem(-30);
          top: rem(12);
        }
      }
    }

    &:not(.o-prizeCollapse-active) {
      #{$root}_title {
        @include tablet-up {
          background: $mountbatten-pink-linear;
          position: relative;

          &::after {
            background: $collpaseTab;
            border-radius: rem(8);
            bottom: 3px;
            content: '';
            left: 3px;
            position: absolute;
            right: 3px;
            top: 3px;
            z-index: 1;
          }
        }
      }
    }
  }

  &_title {
    background: $collpaseTab;
    border-radius: rem(8);
    color: $white;
    font-weight: 600;
    cursor: pointer;
    padding: rem(14) rem(40) rem(14) rem(20);
    position: relative;
    @include font-base(18, 28);

    @include desktop-down {
      padding: rem(12) rem(30) rem(12) rem(14);
      @include font-base(15, 24);
    }

    @include tablet-down {
      padding: rem(10) rem(27) rem(10) rem(12);
      @include font-base(12, 17);
    }

    @include break-max(670px) {
      font-size: rem(10);
    }

    span {
      position: relative;
      z-index: 2;
    }

    &::before {
      background: transparent url('~assets/icons/ic_chevron-down.svg') no-repeat center/100% auto;
      content: '';
      height: rem(24);
      position: absolute;
      right: rem(12);
      top: rem(10);
      transition: $transition-long ease;
      width: rem(24);
      z-index: 2;

      @include desktop-down {
        height: rem(20);
        right: rem(12);
        top: rem(11);
        width: rem(20);
      }

      @include tablet-down {
        height: rem(18);
        right: rem(10);
        top: rem(13);
        width: rem(18);
      }

      @include break-max(670px) {
        right: 5px;
      }
    }
  }

  &_list {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: $transition-long ease;
    visibility: hidden;
    @include scroll-bars;
  }

  &_table {
    padding-top: rem(8);
  }
}