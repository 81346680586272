.t-playerPopup {
  $root: &;

  &_video {
    margin: auto;
    position: relative;
    width: 100%;

    img {
      object-fit: unset;
    }

    &_controls {
      bottom: rem(40) !important;
      left: 50%;
      margin: 0;
      position: absolute;
      transform: translateX(-50%);
      width: 100%;
      @include break-min(1700px) {
        bottom: rem(60) !important;
      }
    }
  }

  &_download {
    background-color: #a81d5d;
    border-radius: rem(8);
    bottom: 4%;
    box-shadow: 0 3px 10px rgba($black, 0.6);
    cursor: pointer;
    padding: rem(8) rem(12);
    position: absolute;
    right: 4%;

    a {
      color: $white;
    }
  }

  &_content {
    bottom: 0;
    left: 0;
    padding-left: 3.8%;
    padding-right: 4.2%;
    padding-top: 4.6%;
    position: absolute;
    right: 0;
    top: 0;

    video {
      display: block;
      height: unset !important;
      margin-left: auto;
      margin-right: auto;
      padding: 0 !important;
    }

    &:hover {
      #{$root}_playBtn.playing {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_speaker {
    cursor: pointer;
    left: 0;
    position: absolute;

    img {
      display: block;
      height: rem(32);
      margin-left: auto;
      margin-right: auto;
      // width: rem(48);
      // height: rem(48);
      width: rem(32);
    }

    p {
      color: #1c4584;
      font-weight: bold;
      margin-top: rem(8);
      visibility: hidden;
      white-space: nowrap;
      @include tablet-down {
        font-size: rem(12);
        margin-top: rem(4);
      }
      @include mobile-down {
        font-size: rem(10);
      }
    }

    &.muted {
      animation: zooming 1s infinite;

      img {
        height: rem(28);
        width: rem(28);
      }

      p {
        visibility: visible;
      }
      @include tablet-down {
        img {
          height: rem(20);
          width: rem(20);
        }
      }
    }
    @include tablet-down {
      img {
        height: rem(20);
        // width: rem(32);
        // height: rem(32);
        width: rem(20);
      }
    }
  }

  &_controls {
    align-items: flex-end;
    bottom: 9.6%;
    display: flex;
    height: 80px;
    justify-content: center;
    left: 4.8%;
    position: absolute;
    right: 6.2%;

    .a-button {
      margin-left: rem(16);
      margin-right: rem(16);
      width: 25%;
    }
    @include tablet-down {
      margin-top: calc(6.6% - 6px);

      .a-button {
        font-size: rem(14);
        height: rem(40);
        margin-left: rem(8);
        margin-right: rem(8);
      }
    }
    @include mobile-down {
      .a-button {
        font-size: rem(11);
        height: rem(36);
        margin-left: rem(16);
        margin-right: 0;

        span {
          padding-top: rem(4);
        }
      }
    }
  }

  &_playBtn {
    cursor: pointer;
    height: rem(48);
    left: 50%;
    position: absolute;
    top: calc(50% - 24px);
    transform: translate(-50%, -50%);
    transition: $transition;
    width: rem(48);

    &.playing {
      opacity: 0;
      visibility: hidden;
    }
  }

  &_review,
  &_next {
    bottom: 10%;
    position: absolute;
    width: rem(150);
    z-index: 102;
    @include tablet-down {
      width: rem(107);
    }

    .a-button {
      @include tablet-down {
        font-size: rem(14);
        height: rem(40);

        span {
          @include font-base(14, 17);
        }
      }
    }
  }

  &_review {
    left: rem(30);
  }

  &_next {
    right: rem(30);
  }

  &_image {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &_iframe {
    height: 100%;
    padding-bottom: 57.77778%;
    position: relative;
    width: 100%;
    @include tablet-down {
      padding-bottom: 54.77778%;
    }

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

@keyframes zooming {
  from {
    transform: scale(0.9);
  }

  to {
    transform: scale(1.1);
  }
}
