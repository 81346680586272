.p-schedule2 {
  padding-bottom: rem(28);
  background: linear-gradient(
    90deg,
    #0189d0 2.24%,
    #0181c9 7.34%,
    #015ea9 34.87%,
    #004492 60.36%,
    #003484 83.81%,
    #002f7f 102.16%
  );
  position: relative;
  padding-top: 52%;
  max-width: rem(880);
  margin-left: auto;
  margin-right: auto;

  @include mobile-down {
    padding-top: 110%;
  }
  padding-bottom: 0;
  background-image: url('~assets/images/ver-banner-2.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  &_wrapper {
    padding-bottom: rem(32);
    // @include mobile-up {
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -8%);
    // }
  }

  &_banner_wrapper {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  &_banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_main,
  &_invitation {
    border-radius: 8px;
    max-width: rem(520);
    padding: rem(36);
    position: absolute;
    right: rem(60);
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-long ease;
    width: 100%;

    @include max-desktop{
      max-width: rem(600);
      padding: rem(80) rem(40);
      right: rem(120);
    }

    @include break-max(1280px) {
      max-width: rem(420);
      padding: rem(30);
    }

    @include tablet-down {
      left: 50%;
      max-width: rem(482);
      top: 41%;
      transform: translateX(-50%);
    }

    @include mobile-down {
      max-width: 88%;
      padding: rem(24) rem(16);
      z-index: 2;
      position: relative;
      left: 0;
      transform: unset;
      top: 0;
      margin-top: 91%;
    }
  }

  &_main {
    margin-bottom: rem(80);
  }

  &_invitation {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  &_main_border {
    clip-path: polygon(
      0 0,
      25% 0,
      25% 2%,
      75% 2%,
      75% 0,
      100% 0,
      100% 85%,
      100% 100%,
      85% 100%,
      15% 100%,
      0 100%,
      0% 85%
    );
    border-radius: rem(24);
    border: rem(2) solid #33c0d1;
    padding: rem(24);

    @include mobile-down {
      clip-path: polygon(
        0 0,
        14% 0,
        20% 2%,
        80% 2%,
        86% 0,
        100% 0,
        100% 85%,
        100% 100%,
        85% 100%,
        15% 100%,
        0 100%,
        0% 85%
      );
      border-radius: rem(24);
      border: rem(2) solid #33c0d1;
      padding: rem(24);
    }

    @media (max-width: 429px) {
      clip-path: polygon(
        0 0,
        12% 0,
        20% 2%,
        80% 2%,
        87% 0,
        100% 0,
        100% 85%,
        100% 100%,
        85% 100%,
        15% 100%,
        0 100%,
        0% 85%
      );
    }
  }

  &_main td {
    padding: rem(4) 0;
  }

  &_main h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(32);
    text-wrap: nowrap;
    margin-top: 0;

    @include desktop-down {
      font-size: rem(24);
    }

    @include mobile-down {
      font-size: rem(18);
      margin-top: 0;
    }

    @media (max-width: 429px) {
      font-size: rem(16);
    }

    @media (max-width: 374px) {
      font-size: rem(15);
    }
  }

  &_main .table-heading h5 {
    width: fit-content;
    border: rem(2) solid #33c0d1;
    margin: 0;
    padding: rem(4) rem(12);
    border-radius: rem(8);
    font-weight: 600;
  }

  &_main .table-heading:first-child {
    min-width: rem(200);

    @include mobile-down {
      min-width: rem(100);
    }
  }

  &_main tr {
    vertical-align: top;
    font-size: rem(24);

    @include mobile-down {
      font-size: rem(14);
    }
  }

  &_invitation p {
    color: $white;
    font-size: rem(36);
    font-weight: bold;
    margin-top: rem(12);
    text-align: left;

    @include mobile-down {
      font-size: rem(24);
      font-weight: 700;
    }
  }

  &_invitation_title {
    height: rem(80);
    color: $white;
    border: none;
    font-weight: 600;
    font-size: rem(32);
    box-shadow: none;
    outline: none;
    background: transparent url('~assets/images/button-frame-1.png') no-repeat center/100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(32);
    padding: rem(8) rem(24) rem(0) rem(24);

    @include mobile-down {
      font-size: rem(18);
      padding: 0;
      width: rem(240);
      height: rem(42);
      border: none;
      font-weight: 600;
      box-shadow: none;
      outline: none;
      background: transparent url('~assets/images/button-frame-1.png') no-repeat center/100% auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem(20);
      // margin-top: 123%;
    }
  }

  &_code {
    width: rem(320);
    height: rem(320);
    aspect-ratio: 1;
    background-color: #ffffff;

    @include mobile-down {
      width: rem(200);
      height: rem(200);
    }
  }

  &_store {
    margin-top: rem(30);
  }

  &_contact {
    width: rem(430);
    height: rem(60);
    font-size: rem(24);
    background: transparent url('~assets/images/button-frame-2.png') no-repeat center/100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'UTM Avo', sans-serif;
    margin: auto;
    margin-top: rem(16);

    @include mobile-down {
      width: rem(300);
      height: rem(40);
      font-size: rem(16);
    }
  }
}
