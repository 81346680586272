.t-numberPickingTemplate {
  &_header {
    background: $header-number-picking;
    padding: rem(10) 0 rem(8);

    @include tablet-down {
      padding: rem(12) 0 rem(10);
    }

    &_wrap {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      @include tablet-down {
        flex-direction: column;
      }
    }

    &_title {
      max-width: rem(515);

      @include tablet-down {
        max-width: rem(328);
      }

      img {
        width: 100%;
      }
    }

    &_welcome {
      @include tablet-up {
        position: absolute;
        right: 0;
        top: 3px;
      }
      @include tablet-down {
        display: flex;
        margin-top: 6px;

        .a-text:last-child {
          margin-left: rem(6);
        }
      }
    }
  }

  &_desc {
    .a-text {
      &:not(:first-child) {
        margin-top: rem(8);
      }
    }
  }

  &_search {
    margin: rem(14) auto 0;
    max-width: rem(460);
    @include adjust-flex(center, center);

    &_label {
      flex: 0 0 rem(173);
      @include mobile-down {
        flex: 0 0 rem(141);
      }

      .a-text {
        @include mobile-down {
          font-size: rem(14);
        }
      }
    }

    &_input {
      margin-left: rem(20);
    }
  }

  &_number {
    margin: rem(17) auto rem(-18);
    max-width: 980px;

    @include tablet-down {
      max-width: 600px;
    }

    @include mobile-down {
      margin: rem(30) auto rem(-11);
      max-width: 270px;
    }

    &_item {
      margin-bottom: rem(18);
      padding-left: rem(11);
      padding-right: rem(11);
      @include mobile-down {
        padding-left: rem(7);
        padding-right: rem(7);
      }
    }
  }

  &_glossary {
    margin-top: rem(15);
    
    @include mobile-up {
      display: flex;
    }

    .label {
      min-width: rem(140);
    }

    &_list {
      margin-top: rem(16);
      width: 100%;
      @include mobile-up {
        margin-left: rem(26);
        margin-top: 0;
        @include adjust-flex(unset, center);
      }
      @include mobile-down {
        margin-left: auto;
        margin-right: auto;
        max-width: rem(200);
      }
    }

    &_item {
      &:not(:first-child) {
        @include mobile-down {
          margin-top: rem(12);
        }
      }
      @include mobile-up {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
    }
  }

  &_review {
    margin-top: rem(24);

    @include mobile-down {
      margin-top: rem(20);
    }

    &_heading {
      @include mobile-down {
        margin: 0 auto;
        max-width: rem(280);
      }
    }

    &_list {
      background: $mountbatten-pink-linear2;
      border-radius: rem(55);
      margin: rem(24) auto 0;
      max-width: rem(841);
      padding: 5px;
      @include mobile-down {
        margin-top: rem(16);
        max-width: rem(320);
      }
      
      &_wrap {
        background: linear-gradient($white, $white, $white, $battleship-gray, $spanish-gray);
        border-radius: rem(55);
        padding-bottom: 4px;
        padding-top: 4px;
        width: 100%;
        @include adjust-flex(center, center);
      }
    }

    &_item {
      padding-left: rem(10);
      padding-right: rem(10);

      @include mobile-down {
        padding-left: 4px;
        padding-right: 4px;
      }
    }
  }

  &_confirm {
    margin-left: auto;
    margin-right: auto;
    max-width: rem(270);
  }
}

.body-numberPicking {
  background: $oxford-blue url('~assets/images/numberChoosing/bg-numberPicking.png') no-repeat center/100% auto;
  @include tablet-down {
    background-image: url('~assets/images/numberChoosing/bg-numberPicking-mobile.png');
  }
}


