@mixin size($width, $height) {
  height: rem($height);
  width: rem($width);
}

.a-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  @include size(24, 24);

  &-12x10 {
    @include size(12, 10);
  }

  &-13x13 {
    @include size(13, 13);
  }

  &-13x13-big {
    @include size(13.5, 13.5);
  }

  &-17x17 {
    @include size(17, 17);
  }

  &-20x20 {
    @include size(20, 20);
  }

  &-24x24 {
    @include size(24, 24);
  }

  &-24x16 {
    @include size(24, 16);
  }

  &-32x32 {
    @include size(32 , 32);
    
    @include tablet-down {
      @include size(20 , 20);
    }
  }

  &-big {
    @include size(75 , 75);
    
    @include tablet-down {
      @include size(45 , 45);
    }
  }

  &-39x39 {
    @include size(39, 39);
  }

  &-location {
    background-image: url('~assets/icons/ic_location.svg');
  }

  &-time {
    background-image: url('~assets/icons/ic_time.svg');
  }

  &-mute {
    background-image: url('~assets/icons/ic_mute.svg');
  }

  &-sound {
    background-image: url('~assets/icons/ic_volume.svg');
  }

  &-muteBlue {
    background-image: url('~assets/icons/ic_mute_blue.png');
  }

  &-soundBlue {
    background-image: url('~assets/icons/ic_unmute_blue.png');
  }

  &-search {
    background-image: url('~assets/icons/ic_search.svg');
  }

  &-expand {
    background-image: url('~assets/icons/ic_fullscreen.svg');
  }

  &-minimize {
    background-image: url('~assets/icons/ic_minimize.svg');
  }

  &-hand {
    background-image: url('~assets/icons/ic_hand.svg');
  }
}

.a-iconButton {
  background-color: unset;
  background-color: $pale-gold-linear;
  border: unset;
  border-radius: 50%;
  padding: 0;
  @include adjust-flex;
  @include size(56, 56);

  @include tablet-down {
    @include size(44, 44);
  }

  &-white {
    background-color: $white;
  }

  &-26 {
    @include size(26, 26);
  }

  &-30 {
    @include size(30, 30);
  }

  &-37 {
    @include size(37, 37);
  }
}
