.a-input {
  $root: &;
  width: 100%;

  &_errorMessage {
    color: $red;
    margin-top: rem(4);
    @include font-base(12, 18);
  }

  &_container {
    position: relative;
    &:not(#{$root}-otp) & {
      background-clip: padding-box, border-box;
      background-color: $white;
      background-image: $input-border;
      background-origin: border-box;
      border: double 1.21px transparent;
      border-radius: rem(8);
      padding: 1px;
      position: relative;
    }
  }

  &-search {
    height: rem(41);
    #{$root}_container {
      background: $pale-gold-linear1;
      border-radius: 5px;
      height: 100%;
      padding: 1px;
      position: relative;
    }
    #{$root}_inputele {
      background-color: rgba($pigment, 0.85);
      color: $white;
      font-weight: 700;
      height: 100%;
      padding: rem(8) rem(14) rem(8) rem(45);
      @include font-base(24, 28);
    }

    img {
      height: rem(24);
      left: 14px;
      position: absolute;
      top: 10px;
      width: rem(24);
    }
  }

  &-secondary {
    #{$root}_label {
      margin-bottom: rem(8);
    }
    #{$root}_inputele {
      background-color: transparent !important;
      border: 1px solid $pigment;
      border-radius: rem(10);
      padding: rem(9) rem(12);
    }
  }

  &-light {
    #{$root}_label {
      margin-bottom: rem(8);
    }
    #{$root}_label .a-text {
      font-weight: 500;
      text-align: center;
    }
    #{$root}_container {
      background: linear-gradient(to left, #97858d, #e9e9e9, #9f9f9f, #efefef, #767676, #efefef, #a7a7a7, #ebebeb);
      border-radius: rem(10);
      padding: 1px;
    }
    #{$root}_inputele {
      background-color: $white !important;
      border: 0;
      border-radius: rem(10);
      padding: rem(8) rem(12);
    }
  }

  &-otp {
    height: rem(48);
    width: rem(48);
    @include mobile-down {
      height: rem(40);
      width: rem(40);
    }
    #{$root}_inputele {
      border: 1px solid $pigment;
      border-radius: 5px;
      height: 100%;
      text-align: center;
      width: 100%;
      @include font-base(20, 28);
      @include mobile-down {
        padding: 4px;
      }
    }
    #{$root}_container {
      height: 100%;
      width: 100%;
    }
  }

  &_inputele {
    appearance: none;
    border: 1px solid $pigment;
    border-radius: 5px;
    color: $pigment;
    margin: 0;
    outline: none;
    transition: all $transition linear;
    width: 100%;

    &:not(#{$root}-otp) & {
      border: 0;
      border-radius: rem(8);
      padding: rem(14) rem(20);
    }

    @include tablet-down {
      padding: rem(8) rem(16);
    }
    @include font-base(16, 28);

    &-error {
      border-color: $red;
    }

    &-noCursor {
      pointer-events: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }

    // &::placeholder {
    //   color: $pastel-blue;
    // }
  }

  &_label {
    margin-bottom: rem(22);
    @include tablet-down {
      margin-bottom: rem(10);
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }
}
