@font-face {
  font-display: swap;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}MyriadPro-Regular.woff2') format('woff2'),
    url('#{$font-path}MyriadPro-Regular.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'UTM Avo';
  font-style: normal;
  font-weight: bold;
  src: url('#{$font-path}UTMAvoBold.woff2') format('woff2'),
    url('#{$font-path}UTMAvoBold.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SFU Freeway';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}SFUFreewayRoman.woff2') format('woff2'),
    url('#{$font-path}SFUFreewayRoman.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'UTM Avo';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}UTMAvo.woff2') format('woff2'),
    url('#{$font-path}UTMAvo.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SFU Freeway';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-path}SFUFreewayDemi.woff2') format('woff2'),
    url('#{$font-path}SFUFreewayDemi.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SFU Freeway';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}SFUFreewayLight.woff2') format('woff2'),
    url('#{$font-path}SFUFreewayLight.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'SFU Freeway';
  font-style: normal;
  font-weight: 900;
  src: url('#{$font-path}SFUFreewayBlack.woff2') format('woff2'),
    url('#{$font-path}SFUFreewayBlack.woff') format('woff');
}

@font-face {
  font-family: 'SVN-Brandon Grotesque';
  src: url('#{$font-path}SVN-BrandonGrotesqueBlack.woff2') format('woff2'),
      url('#{$font-path}SVN-BrandonGrotesqueBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Brandon Grotesque';
  src: url('#{$font-path}SVN-BrandonGrotesqueBold.woff2') format('woff2'),
      url('#{$font-path}SVN-BrandonGrotesqueBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SVN-Brandon Grotesque';
  src: url('#{$font-path}SVN-BrandonGrotesque.woff2') format('woff2'),
      url('#{$font-path}SVN-BrandonGrotesque.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
