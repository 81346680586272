.t-numberLogin {
  position: relative;

  @include small-mobile {
    background-color: #0153b4;
    min-height: 100vh;
    padding-bottom: rem(20);
    @include adjust-flex(center, center);
  }

  &_background {
    @include small-mobile {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .a-image {
      @include tablet-down {
        @include aspect-ratio(700, 1024);
      }

      @include mobile-down {
        @include aspect-ratio(375, 576);
      }

      @include small-mobile {
        height: 100%;

        img {
          object-position: top;
          top: rem(-12);
        }
      }
    }
  }

  &_logo {
    left: rem(31);
    position: absolute;
    top: rem(25);
    transition: $transition-long ease;
    width: rem(282);
    z-index: $z-1;

    @include mobile-down {
      left: 50%;
      top: rem(16);
      width: rem(210);
      transform: translateX(-50%);
    }

    img {
      width: 100%;
    }
  }

  &_form {
    background: $anti-flash-white;
    border: 2px solid $yale-blue;
    border-radius: 8px;
    box-shadow: 0 0 14px rgba($black, 0.25);
    max-width: rem(520);
    padding: rem(36);
    position: absolute;
    right: rem(60);
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-long ease;
    width: 100%;

    @include max-desktop{
      max-width: rem(600);
      padding: rem(80) rem(40);
      right: rem(120);
    }

    @include break-max(1280px) {
      max-width: rem(420);
      padding: rem(30);
    }

    @include tablet-down {
      left: 50%;
      max-width: rem(482);
      top: 41%;
      transform: translateX(-50%);
    }

    @include mobile-down {
      max-width: 88%;
      padding: rem(24) rem(16);
      z-index: 2;
      position: relative;
      left: 0;
      transform: unset;
      top: 0;
      margin-top: 100%;
    }

    @include smallest-mobile{
      margin-top: 112%;
    }

    &_desc {
      margin: rem(12) auto 0;
      max-width: rem(357);
    }

    &_wrap {
      margin-top: rem(18);
      position: relative;

      @include small-mobile {
        margin-top: rem(24);
      }
    }

    &_input {
      &:not(:first-child) {
        margin-top: rem(24);

        @include mobile-down {
          margin-top: rem(16);
        }
      }
    }

    &_button {
      margin: rem(20) auto 0;
      max-width: rem(260);

      @include mobile-down {
        margin-top: rem(14);
      }
    }

    &_label {
      border-bottom: 1px solid $pigment;
      margin-top: rem(12);
      padding-bottom: rem(24);

      @include mobile-down {
        padding-bottom: rem(18);
      }
    }
  }
}