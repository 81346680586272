.a-heading {
  $root: &;
  margin: 0;
  @include font-base(24, 33);

  @include tablet-down {
    @include font-base(20, 27);
  }

  &-xl {
    @include font-base(40, 52);

    @include desktop-up {
      @include font-base(60, 76);
    }

    @include tablet-down {
      @include font-base(28, 36);
    }
  }

  &-lg {
    @include font-base(32, 47);

    @include tablet-down {
      @include font-base(18, 26);
    }
  }

  &-md {
    @include font-base(20, 27);

    @include desktop-down {
      @include font-base(18, 26);
    }

    @include tablet-down {
      @include font-base(15, 22);
    }
  }

  &-goldLinear {
    background: linear-gradient(80.5deg, #c79f33 1.7%, #e4d050 19.22%, #f6e468 25.78%, #f7e692 31.65%, #ebd588 41.98%, #e4d050 47.2%, #c7aa71 71.35%, #ebd468 75.57%, #efde75 82.87%, #d8c046 92.28%, #c79f33 98.53%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  &-goldLinear2 {
    background: linear-gradient(111.77deg, #D58800 0%, #F2AA00 8%, #FAD87B 20%, #FFEFB7 25%, #FBE5A5 30%, #F0CC78 39%, #E0A331 52%, #D58800 60%, #E9BB5B 69%, #FFEFB7 79%, #F2D05F 87%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }

  &-prizeSpecial {
    background-image: linear-gradient(101deg, #7A4D22 0%, #DDB161 31.25%, #8B6A1A 53.94%, #CAB66E 72.41%, #B98300 92.32%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @include font-base(90, 110);

    span {
      @include font-base(60, 110);
      margin-right: rem(12);
    }

    sup {
      @include font-base(60, 110);
    }
  }

  @include style-color();
  @include style-font-weight();
  @include style-general();
  @include font-family();
}