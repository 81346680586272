.a-imageButton {
  background: transparent;
  border: 0;
  border-radius: 50%;
  height: rem(100);
  outline: 0;
  padding: 0;
  position: relative;
  width: rem(100);
  @include tablet-down {
    height: rem(60);
    width: rem(62);
  }

  &-disabled {
    filter: grayscale(100%);
    pointer-events: none;
  }

  &-capture,
 
  &-save {
    height: rem(120);
    width: rem(120);
    @include tablet-down {
      height: rem(80);
      width: rem(84);
    }
  }

  &:active {
    box-shadow: 0 3px 10px rgba($black, 0.4);
  }

  img {
    height: 100%;
    width: 100%;
  }
}
