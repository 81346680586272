// Base Color
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); //#000000
$pigment: rgb(36, 62, 144); //#243E90
$rich-black: rgb(0, 9, 47); //00092f
$pale-golden-rod: rgb(243, 238, 172); //#F3EEAC
$satin-sheen-gold: rgb(207, 158, 55); //#CF9E37
$metallic: rgb(210, 165, 65); //#D2A541
$earth-yellow: rgb(219, 184, 93); //#DBB85D
$flax: rgb(233, 215, 138); //#E9D78A
$flax2: rgb(237, 213, 134); //#EDD586
$rich-electric-blue: rgb(0, 156, 222); //#009CDE
$rich-electric-blue2: rgb(1, 156, 222); //#019CDE
$jet: rgb(51, 51, 51); //#333333
$platinum: rgb(228, 228, 228); //#e4e4e4
$whitesmoke: rgb(245, 245, 245); //#f5f5f5
$egyptian-blue: rgb(19, 58, 151); //#133A97
$green-blue: rgb(11, 102, 183); //#0B66B7
$sunray: rgb(212, 173, 79); //#D4AD4F
$lemon-chiffon: rgb(252, 248, 206); //#FCF8CE
$blond: rgb(248, 240, 189); //#F8F0BD
$light-khaki: rgb(239, 218, 144); //#EFDA90
$flavescent: rgb(254, 231, 154); //#FEE79A
$university-cali-gold: rgb(192, 145, 45); //#C0912D
$liver: rgb(99, 49, 21); //#633115
$metallic-sunburst: rgb(159, 123, 45); //#9f7b2d
$pale-gold: rgb(220, 206, 136); //#dcce88
$sepia: rgb(117, 70, 28); //#75461c
$burlywood: rgb(218, 200, 128); //#dac880
$red: rgb(255, 0, 0); //#FF0000
$royal-azure: rgb(5, 53, 164); //#0535A4
$rich-electric-blue3: rgb(0, 150, 213); //#0096D5
$anti-flash-white: rgb(241, 242, 242); //#f1f2f2
$anti-flash-white2: rgb(242, 242, 242); //#F2F2F2
$anti-flash-white3: rgb(240, 240, 240); //#f0f0f0 
$taupe-gray: rgb(140, 140, 140); //#8C8C8C
$yale-blue: rgb(17, 74, 163); //#114AA3
$dark-liver: rgb(79, 79, 79); //#4F4F4F
$oxford-blue: rgb(19, 11, 64); //#130b40 
$russet: rgb(123, 67, 24); //#7B4318
$jasmine: rgb(252, 219, 122); //#FCDB7A
$jasmine2: rgb(249, 226, 113); //#F9E271
$spanish-gray: rgb(145, 145, 145); //#919191
$battleship-gray: rgb(133, 133, 133); //#858585
$alice-blue: rgb(242, 247, 253); //#F2F7FD
$dark-cerulean: rgb(16, 77, 130); //#104D82
$egyptian-blue: rgb(19, 56, 150); //#133896
$dim-gray: rgb(109, 110, 113); //#6d6e71
$dark-goldenrod: rgb(185, 131, 0); //#b98300

//Overlay
$white-overlay044: rgba($white, 0.44);
$black-overlay05: rgba($black, 0.5);

// Linear
$primary-linear: linear-gradient(80.5deg,
        $pale-golden-rod 1.7%,
        $satin-sheen-gold 19.22%,
        $metallic 25.78%,
        $earth-yellow 31.65%,
        $flax 41.98%,
        $pale-golden-rod 47.2%,
        $satin-sheen-gold 71.35%,
        $metallic 75.57%,
        $earth-yellow 82.87%,
        $flax 92.28%,
        $pale-golden-rod 98.53%);
$platinum-linear: linear-gradient(90deg,
        rgba($platinum, 0) 1.09%,
        rgba($white, 0.64) 47.23%,
        rgba($whitesmoke, 0) 100%);
$blue-linear: linear-gradient(180deg,
        $egyptian-blue 0%,
        $green-blue 63.88%,
        $rich-electric-blue2 106.25%);
$yellow-gold-linear: linear-gradient(80.5deg,
        $pale-golden-rod -9.93%,
        $earth-yellow 22.78%,
        $flax 34.41%,
        $pale-golden-rod 54.69%,
        $earth-yellow 82.87%);
$sunray-linear: linear-gradient(90deg,
        $sunray -0.01%,
        $lemon-chiffon 25.27%,
        $blond 33.43%,
        $light-khaki 49.57%,
        $flax2 52.99%,
        $flavescent 75.42%,
        $university-cali-gold 100%);
$mountbatten-pink-linear: linear-gradient(0deg,
        #633115 0%,
        #9f7b2d 15%,
        #dcce88 36%,
        #f3eeac 44%,
        #dac880 70%,
        #f3eeac 86%,
        #75461c 100%);
$mountbatten-pink-linear: linear-gradient(0deg,
        #97858d 8.57%,
        #e9e9e9 18.27%,
        #9f9f9f 29.74%,
        #efefef 44.74%,
        #767676 55.32%,
        #efefef 64.15%,
        #a7a7a7 82.67%,
        #ebebeb 96.79%);
$mountbatten-pink-linear2: linear-gradient(5deg,
        #97858d 8.57%,
        #e9e9e9 18.27%,
        #9f9f9f 29.74%,
        #efefef 44.74%,
        #767676 55.32%,
        #efefef 64.15%,
        #a7a7a7 82.67%,
        #ebebeb 96.79%);
$pale-gold-linear: linear-gradient(0deg,
        $liver 0%,
        $metallic-sunburst 15%,
        $pale-gold 36%,
        $pale-golden-rod 44%,
        $burlywood 70%,
        $pale-golden-rod 86%,
        $sepia 100%);
$pale-gold-linear1: linear-gradient(80.5deg,
        #f3eeac 1.7%,
        $satin-sheen-gold 19.22%,
        #d2a541 25.78%,
        #dbb85d 31.65%,
        $flax 41.98%,
        #f3eeac 47.2%,
        $satin-sheen-gold 71.35%,
        #d2a541 75.57%,
        #dbb85d 82.87%,
        #e9d78a 92.28%,
        #f3eeac 98.53%);
$inner-border-gold-linear: #ffe375;

$inner-border-gold-linear2: linear-gradient(6deg,
#75461c 0.46%,
#9f7b2d 12.47%,
#dcce88 24.68%,
#f3eeac 29.27%,
#dac880 47.45%,
#c0a068 58.6%,
#c7aa71 62.91%,
#d9c689 68.89%,
#f3eeac 79.33%,
#75461c 97.07%);
$input-border: linear-gradient(90deg,
        #97858d -0.19%,
        #e9e9e9 11.13%,
        #9f9f9f 24.08%,
        #efefef 40.52%,
        #767676 52.8%,
        #efefef 63.45%,
        #a7a7a7 84.4%,
        #ebebeb 100.19%);
$section-border: linear-gradient(180deg,
        #b68c2e 0%,
        #faf075 27.3%,
        #8b5b24 66.22%,
        #e4c655 94.32%,
        #281b0f 100%);
$form-linear: linear-gradient(137.51deg,
        #fff 0.92%,
        #ccc 24.22%,
        #fff 54.66%,
        #ccc 80.69%,
        #fff 99.07%);
$term-linear: linear-gradient(90deg,
        rgba(228, 228, 228, 0) 1.09%,
        rgba(216, 216, 216, 0.3) 47.23%,
        rgba(245, 245, 245, 0.78) 100%);
$title-linear: linear-gradient(90deg,
        rgba(228, 228, 228, 0) 1.09%,
        #cbcbcb 51.29%,
        rgba(245, 245, 245, 0) 100%);
$videos-linear: linear-gradient(121.6deg,
        #a7a7a7 0.47%,
        #e9e9e9 11.65%,
        #9f9f9f 24.42%,
        #efefef 40.65%,
        #767676 52.76%,
        #efefef 63.27%,
        #a7a7a7 83.95%,
        #ebebeb 99.53%);
$videos-border-linear: linear-gradient(57.98deg,
        #a7a7a7 0.89%,
        #e9e9e9 11.97%,
        #9f9f9f 24.64%,
        #efefef 40.73%,
        #767676 52.74%,
        #efefef 63.16%,
        #a7a7a7 83.65%,
        #ebebeb 99.1%);
$show-content-linear: linear-gradient(180deg,
        #133a97 0%,
        #0b66b7 64.42%,
        #019cde 107.16%);
$show-content-linear-bottom: linear-gradient(0deg,
        #133a97 0%,
        #0b66b7 64.42%,
        #019cde 107.16%);
$table-event-linear: linear-gradient(90deg,
        rgba(212, 173, 79, 0.2) -0.01%,
        rgba(252, 248, 206, 0.2) 25.27%,
        rgba(248, 240, 189, 0.2) 33.43%,
        rgba(239, 218, 144, 0.2) 49.57%,
        rgba(237, 213, 134, 0.2) 52.99%,
        rgba(254, 231, 154, 0.2) 75.42%,
        rgba(192, 145, 45, 0.2) 100%);
$table-header-linear: linear-gradient(80.5deg,
        #f3eeac -9.93%,
        #dbb85d 22.78%,
        #e9d78a 34.41%,
        #f3eeac 54.69%,
        #dbb85d 82.87%);

$spanish-gray-linear: linear-gradient(87.44deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 6%,
        #999 13%,
        #efefef 40%,
        #efefef 71%,
        #d9d9d9 85%,
        rgba(239, 239, 239, 0) 96%);
$white-gray-linear: linear-gradient(52.75deg,
        #999 -35.66%,
        #e9e9e9 -13.2%,
        #9f9f9f 10.65%,
        #efefef 47.46%,
        #efefef 77.81%,
        #a7a7a7 97.44%);
$blue-text-linear: linear-gradient(86.64deg, #009cde -18.11%, #243e90 100.62%);
$egyptian-blue-linear: linear-gradient(180deg,
        #133a97 0%,
        #0b66b7 24.91%,
        #019cde 49.7%,
        #133997 100.78%);

$menu-linear: linear-gradient(180deg,
        #133a97 0%,
        #0b66b7 24.91%,
        #019cde 49.7%,
        #133997 100.78%);

$bg-guide-linear: linear-gradient(0deg,
        #fff 0%,
        #ccc 23.74%,
        #fff 54.75%,
        #ccc 81.28%,
        #fff 100%);
$bg-footer-linear: linear-gradient(180deg,
        #133a97 0%,
        #0b66b7 24.91%,
        #019cde 49.7%,
        #133997 100.78%);
$bg-footer-phone-linear: linear-gradient(86.63deg,
        #a1a1a1 -14.34%,
        #acacac -7.2%,
        #c7c7c7 5.08%,
        #e8e8e8 16.95%,
        #e8e8e8 82.45%,
        #a1a1a1 115.93%);

$collpaseTab: linear-gradient(360deg, #185f9a -4.17%, #3ebdf1 12.65%, #1562fb 26.7%, #0a4fd6 41.14%, #0a4fd6 65.83%, #1562fb 77.22%, #3ebdf1 95.45%, #0a2db3 106.64%);
$collpaseTabActive: linear-gradient(145.98deg, #d58800 0%, #f2aa00 11.72%, #fbd97b 29.81%, #ffefb7 38.31%, #fbe5a6 45.22%, #f1cc79 58.96%, #e0a431 78.04%, #d58800 90.23%, #eabb5b 104.99%, #ffefb7 119.22%, #f2d05f 131.95%);

$header-number-picking: linear-gradient(0deg, #140c40 -0.03%, #191d5b 18.72%, #243e90 49.99%, #213480 63.21%, #181954 89.23%, #140c40 100%);
$yellow-linear: linear-gradient(140.55deg, #d58800 -0.07%, #f2aa00 54.21%, #fbd97b 138%, #ffefb7 177.38%, #fbe5a6 209.39%, #f1cc79 273.05%, #e0a431 361.43%, #d58800 417.94%, #eabb5b 486.3%, #ffefb7 552.22%, #f2d05f 611.18%);
$blue-linear-button: linear-gradient(360deg, #185f9a -4.17%, #3ebdf1 12.65%, #1562fb 26.7%, #0a4fd6 41.14%, #0a4fd6 65.83%, #1562fb 77.22%, #3ebdf1 95.45%, #0a2db3 106.64%);