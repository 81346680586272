.t-registerSecond {
  position: relative;

  &-resize {
    .o-numberBannerLayout {
      @include desktop-up {
        min-height: 1080px;
      }
    }

    .t-registerForm {
      @include desktop-up {
        padding-top: rem(20);
      }
    }
  }

  &_form {
    max-width: rem(568);
    padding: 4px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @include small-mobile-up {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .o-numberBannerLayout_background {
    @include small-mobile {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      overflow: hidden;
    }
  }

  .o-numberBannerLayout {
    @include small-mobile {
      padding-bottom: rem(80);
      padding-top: 8%;
    }
  }
}