.t-forgetPassword {
  position: relative;

  &_form {
    background: linear-gradient(to left, #a7a7a7, #e9e9e9, #9f9f9f, #efefef, #767676, #efefef, #a7a7a7, #ebebeb);
    left: 50%;
    max-width: rem(568);
    padding: 4px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include mobile-down {
      top: 46%;
    }

    @include small-mobile {
      max-width: rem(345);
    }
    
    &_heading {
      margin: 0 auto;
      max-width: rem(362);

      @include tablet-down {
        max-width: rem(282);
      }

      .m-headingSection{
        white-space: nowrap;
        @include mobile-down {
          span{
            font-size: rem(16) !important;
          }
        }
      }
    }
    
    &_border {
      background: linear-gradient(161.19deg, #fff 0.96%, #ccc 24.24%, #fff 54.65%, #ccc 80.67%, #fff 99.03%);
      padding: rem(42) rem(40);

      @include mobile-down {
        padding: rem(32) rem(16);
      }
    }

    &_desc {
      background: linear-gradient(90deg, rgba(228, 228, 228, 0) 1.09%, #d8d8d8 47.23%, rgba(245, 245, 245, 0) 100%);
      border: 1px solid rgba($white, 0.44);
      border-radius: rem(10);
      margin-top: rem(32);
      padding: rem(8) rem(10);
      position: relative;

      &.blur {
        &::before,
   
        &::after {
          background: linear-gradient(90deg, rgba(228, 228, 228, 0) 1.09%, rgba(255, 255, 255, 0.64) 47.23%, rgba(245, 245, 245, 0) 100%);
          border-radius: 10px;
          content: '';
          height: 5px;
          left: 50%;
          position: absolute;
          transform: translateX(-50%);
          width: 60%;
        }
        
        &::before {
          top: -2px;
        }
  
        &::after {
          bottom: -2px;
        }
      }

      .a-text {
        @include small-mobile {
          margin: 0 auto;
          max-width: rem(214);
        }
      }

    }

    &_wrap {
      margin-top: rem(24);

      @include mobile-down {
        margin-top: rem(16);
      }
    }

    &_input {
      .a-text {
        font-weight: 600 !important;
      }
    }

    &_button {
      margin: rem(42) auto 0;
      max-width: rem(280);

      @include mobile-down {
        margin-top: rem(30);
      }
    }
  }
}
