.t-otpPopup {
  &_content {
    @include tablet-down {
      text-align: center;
    }
  }

  &_reset {
    margin-top: rem(27.39);
    text-align: center;
    @include adjust-flex(center, center);

    .label {
      margin-right: rem(8);
    }

    .cursor {
      cursor: pointer;
    }
  }

  &_error {
    color: $red;
    margin-top: rem(4);
    text-align: center;
    @include font-base(12, 18);
  }

  &_button {
    margin-top: rem(23.68);
    text-align: center;

    .a-button {
      margin: auto;
      max-width: rem(190);
    }
  }

}
