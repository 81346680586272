.t-confirmNumberPopup {
  &_slider {
    display: flex;
    flex-wrap: wrap;
    margin: rem(14) rem(-4) rem(-8);
    max-height: rem(240);
    overflow: auto;
    padding: 5px;
    @include scroll-bars;

    @include desktop-down {
      margin-bottom: rem(10);
      max-height: rem(208);
    }
    @include small-mobile {
      margin: rem(12) auto rem(10);
      max-height: rem(336);
      max-width: rem(293);
    }

    &_item {
      @include adjust-flex(center, center);
      flex: 0 0 16%;
      margin-bottom: rem(8);
      max-width: 16%;
      padding-left: rem(4);
      padding-right: rem(4);

      @include small-mobile {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }

    .slick-track {
      padding-bottom: 2px;

      @include mobile-down {
        padding-bottom: 5px;
      }
    }

    .o-carousel_arrow {
      top: 57%;
    }

    .o-carousel_arrow_next {
      right: -10%;
    }

    .o-carousel_arrow_prev {
      left: -10%;
    }
  }
}
