.a-radio {
  $root: &;
  display: inline-block;
  position: relative;

  // &-davysGrey {
  //   #{$root}_main_label {
  //     color: $davys-grey !important;
  //   }
  // }

  &_main {
    align-items: center;
    cursor: pointer;
    display: flex;
    position: relative;

    &_input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      z-index: 1;
    }

    &_label {
      @include font-base(24, 32);
      color: $pigment;
      cursor: inherit;
      display: inline-block;
      font-family: $font-sfu-freeway;
      font-weight: normal;
      padding-left: rem(32);
      @include tablet-down {
        @include font-base(16, 22);
      }

      &-md {
        padding-left: rem(28);
      }
    }
  }

  &_checkmark {
    background: $white;
    border-radius: 50%;
    height: rem(14);
    left: 0;
    margin-top: rem(2);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: rem(14);

    &::after {
      border: 1px solid $pigment;
      border-radius: 50%;
      content: '';
      display: block;
      height: rem(14);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: $transition;
      width: rem(14);
    }
  }

  &_main_input {
    &:checked ~ #{$root}_checkmark {
      background: $white;
    }

    &:checked ~ #{$root}_checkmark:after {
      border: solid rem(4) $pigment;
      display: block;
    }
  }
}
