html,
body {
  background-color: $white;
  font-family: $font-brandon;
  font-size: 16px;
  overflow-x: hidden;
  @include scroll-bars(6, $dark-liver, transparent, 4, 1);
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
  &:focus {
    outline: none;
  }
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  text-decoration: none;
}

[role='button'],
button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

input {
  word-break: normal;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.hidden-animation {
  visibility: hidden;
}

.overflow-hidden {
  height: 100vh;
}

.marginTop {
  margin-top: rem(90);
}

.mt-24 {
  margin-top: rem(24);

  @include mobile-down {
    margin-top: rem(16);
  }
}

.body-background {
  background: transparent url('~assets/images/prizes/bg-prizeTable.jpg') no-repeat;
  background-position: top left;
  background-size: cover;

  body {
    background-color: transparent;
  }
}

.tag-save {
  background-color: $anti-flash-white3;
  border-radius: rem(12);
  box-shadow: 0 3px 10px rgba($black, 0.5);
  left: 50%;
  padding: rem(8) rem(12);
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  @include font-base(10, 15);
}