.m-headingSection {
  $root: &;
  background: $mountbatten-pink-linear;
  border-radius: rem(100);
  color: $pigment;
  margin: 0;
  padding: rem(17) rem(35);
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all $transition ease;
  width: 100%;
  @include reset-button;
  @include adjust-flex;

  @include tablet-down {
    min-width: rem(286);
    padding: rem(14) rem(16);
  }

  @include mobile-down {
    min-width: 100%;
  }

  &::before {
    // background: $pale-gold-linear;
    background: $inner-border-gold-linear;
    border-radius: rem(100);
    content: '';
    height: calc(100% - #{rem(15)});
    position: absolute;
    width: calc(100% - #{rem(15)});
  }

  &-secondary {
    background: $inner-border-gold-linear2;
    border-radius: rem(10);
    padding: rem(10) rem(30);

    &::before {
      background: $inner-border-gold-linear;
      border-radius: rem(10);
      height: calc(100% - #{rem(7)});
      width: calc(100% - #{rem(7)});
    }

    span {
      font-size: rem(20) !important;
      line-height: rem(27) !important;
    }
  }

  span {
    color: $pigment;
    font-weight: 700;
    position: relative;
    @include font-base(24, 33);
    margin-top: rem(8);

    @include tablet-down {
      @include font-base(20, 27);
    }

    @include tablet-down {
      @include font-base(18, 26);
      font-weight: 700;
    }
  }
}