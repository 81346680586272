// fonts
$font-path: '~assets/fonts/';
$font-myriad: 'SVN-Brandon Grotesque';
$font-utmavo: 'SVN-Brandon Grotesque';
$font-sfu-freeway: 'SVN-Brandon Grotesque';
$font-brandon: 'SVN-Brandon Grotesque';
// transition
$transition: 0.25s;
$transition-long: 0.4s;

// z-index
$z-header: 100;
$z-modal: 101;
$z-1: 1;
$z-2: 2;
$z-99: 99;

// value
$width-container-value: 1366;
$standard-viewport: 1366px;
$w: 1 / $width-container-value * 100vw;
$h: 1/800 * 100vh;

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true;
$moz: false;
$ms: false;
$o: false;

$offsets: (top, bottom, left, right);
$breakpoints: (
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
);
