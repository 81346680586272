.t-showInfo {
  padding-top: rem(80);

  @include tablet-down {
    padding-top: rem(40);
  }

  &_inner {
    background-clip: padding-box, border-box;
    background-color: $white;
    background-image: $videos-border-linear;
    background-origin: border-box;
    border: double 1.21px transparent;
    border-radius: rem(8);
    padding: rem(8);
    position: relative;

    @include mobile-down {
      background-clip: unset;
      background-color: transparent;
      background-image: unset;
      background-origin: unset;
      border: 0;
      border-radius: unset;
      padding: 0;
    }
  }

  &_wrapper {
    background-image: $videos-linear;
    padding: rem(60) rem(12) rem(12) rem(12);
    position: relative;

    @include tablet-down {
      padding: rem(48) rem(12) rem(12) rem(12);
    }

    @include mobile-down {
      background-image: unset;
      padding: rem(32) rem(12) rem(12) rem(12);
    }

    video {
      height: 100%;
      width: 100%;
    }
  }

  &_title {
    left: 50%;
    position: absolute;
    top: rem(-32);
    transform: translateX(-50%);
    z-index: $z-1;

    @include tablet-down {
      margin: auto;
      max-width: rem(308);
    }

    @include mobile-down {
      position: static;
      text-align: center;
      transform: unset;

      .a-button {
        margin: 0 auto;
        max-width: rem(340);

        @include tablet-down {
          max-width: rem(300);
          padding: rem(14) rem(12);
        }
      }
    }

    .a-button {
      text-transform: uppercase;
    }
  }

  &_mute {
    bottom: 4%;
    cursor: pointer;
    left: 3%;
    position: absolute;

    @include mobile-down {
      bottom: 8%;
      left: 6%;
    }
  }

  &_fullscreen {
    bottom: 4%;
    cursor: pointer;
    right: 3%;
    position: absolute;

    @include mobile-down {
      bottom: 8%;
      right: 6%;
    }
  }
}