.t-registerForm {
  $root: &;
  padding-top: rem(80);

  @include tablet-down {
    padding-top: rem(40);
  }

  .t-forgetPassword_form{
    overflow-y: auto;
  }

  &_wrapper {
    background-image: $form-linear;
    flex-direction: column;
    padding: rem(72/2) rem(32) rem(48/4) rem(32);
    border-radius: rem(14);
    @include adjust-flex(flex-start, center);

    @include tablet-down {
      padding: rem(48) rem(24) rem(40) rem(24);
    }

    @include mobile-down {
      padding: rem(40) rem(12);
    }

    &_title{
      max-width: rem(480);
      width: 100%;
    }
  }

  &_title {
    margin-top: rem(42);

    @include mobile-down {
      margin-top: rem(16);
    }
  }

  &_noti,
  &_title {
    background-image: $title-linear;
    border: 1px solid $white-overlay044;
    border-radius: rem(10);
    padding: rem(8) rem(24);

    &-bg {
      background-image: $term-linear;
      cursor: pointer;

      @include tablet-down {
        background-image: unset;
      }
    }
  }

  &_noti {
    margin-bottom: rem(18/4);
    margin-top: rem(32/4);

    @include tablet-down {
      margin-bottom: rem(24);
      margin-top: rem(32);
    }

    @include mobile-down {
      margin-bottom: rem(16);
      margin-top: rem(24);
    }
  }

  &_formWrapper {
    flex-direction: column;
    margin-top: rem(45/4);
    width: 100%;
    @include adjust-flex(flex-start, center);

    @include tablet-down {
      margin-top: rem(48);
    }

    @include mobile-down {
      margin-top: rem(16);
    }

    &-field {
      margin-left: auto;
      margin-right: auto;
      max-width: rem(582);
      width: 100%;

      &-mb {
        margin-bottom: rem(18);
      }
    }
  }

  &_term {
    margin-bottom: rem(28/4);
    margin-top: rem(45/4);
    text-align: center;

    @include tablet-down {
      margin-top: rem(48);
    }

    @include mobile-down {
      margin-top: rem(24);
    }

    &_checkbox {
      display: flex;
      justify-content: center;
      margin-top: rem(12);
      text-align: left;
    }

    &-button {
      margin: rem(45/2) auto 0;
      max-width: rem(224);

      @include mobile-down {
        max-width: rem(160);
      }

      .a-button {
        margin-left: auto;
        margin-right: auto;
      }

      @include tablet-down {
        margin-top: rem(24);
      }

      @include mobile-down {
        margin-top: rem(16);
      }
    }
  }

  &_error {
    color: $red;
    margin-top: rem(4);
    @include font-base(12, 18);
  }

  &-secondary {
    #{$root}_wrapper {
      padding-top: rem(18);
    }

    .m-sectionTitle_title {
      position: static;
      transform: none;
    }

    // .m-headingSection {
    //   border-radius: 0;
    //   border-top-left-radius: rem(6);
    //   border-top-right-radius: rem(6);

    //   &::before {
    //     border-radius: 0;
    //     border-top-left-radius: rem(6);
    //     border-top-right-radius: rem(6);
    //   }
    // }
  }

  .a-input_inputele {
    padding: rem(14/2) rem(20/2);
  }

  .a-input_label {
    margin-bottom: rem(22/4);
  }
}