.m-numberBall {
  $root: &;
  align-items: center;
  // background: transparent url('~assets/images/numberChoosing/ball-normal.png') no-repeat center/100% auto;
  border-radius: 50%;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: $font-myriad;
  font-weight: 600;
  height: rem(80);
  justify-content: center;
  position: relative;
  width: rem(80);
  @include font-base(32, 38);

  @include mobile-down {
    height: rem(68);
    width: rem(68);
    @include font-base(22, 28);
  }

  //Size
  &-lg {
    height: rem(112);
    width: rem(112);

    @include mobile-down {
      height: rem(60);
      width: rem(60);
      @include font-base(20, 24);
    }
  }

  &-sm {
    height: rem(70);
    width: rem(70);
    @include font-base(22, 24);

    @include mobile-down {
      height: rem(60);
      width: rem(60);
      @include font-base(18, 22);
    }
  }

  // //Type
  &-chosen {
    color: $jet;
    pointer-events: none;

    .background {
      background-image: url('~assets/images/numberChoosing/ball-chosen.png') !important;
      mix-blend-mode: normal !important;
    }
  }

  &-silver {
    background: transparent url('~assets/images/numberChoosing/ball-all.png') no-repeat center/100% auto;
    mix-blend-mode: normal;
  }

  &-picking,
  &-picked {
    color: $russet;

    .background {
      background-image: url('~assets/images/numberChoosing/ball-picking.png') !important;
      mix-blend-mode: normal !important;
    }
  }

  &-label {
    background: none;
    color: $white !important;
    cursor: auto;
    height: auto;
    pointer-events: all;
    width: auto;

    #{$root}_ball {
      background: transparent url('~assets/images/numberChoosing/ball-normal.png') no-repeat center/100% auto;
      height: rem(38);
      width: rem(38);

      @include mobile-down {
        height: rem(24);
        width: rem(24);
      }
    }

    &#{$root}-chosen {
      #{$root}_ball {
        background-image: url('~assets/images/numberChoosing/ball-chosen.png');
        mix-blend-mode: normal;
      }
    }

    &#{$root}-picking {
      #{$root}_ball {
        background-image: url('~assets/images/numberChoosing/ball-picking.png');
        mix-blend-mode: normal;
      }
    }
  }

  &-disabled {
    pointer-events: none;
  }

  &_label {
    font-weight: 400;
    margin-left: rem(10);
    @include font-base(20, 24);

    @include mobile-down {
      @include font-base(16, 19);
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    visibility: hidden;

    &:checked+.background {
      background-image: url('~assets/images/numberChoosing/ball-picking.png');

      &+.value {
        color: $russet;
      }
    }
  }

  span{
    display: inline-block;
    margin-top: rem(6);
  }

  .background {
    background: transparent url('~assets/images/numberChoosing/ball-normal.png') no-repeat center/100% auto;
    border-radius: 50%;
    bottom: 0;
    left: 0;
    // mix-blend-mode: hard-light;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-1;
  }

  .value {
    position: relative;
    z-index: $z-2;
    font-weight: 700;
    font-family: $font-utmavo;
  }
}