.t-waiting {
  &_panel {
    background: linear-gradient(to left, #a7a7a7, #e9e9e9, #9f9f9f, #efefef, #767676, #efefef, #a7a7a7, #ebebeb);
    max-width: rem(968);
    position: relative;
    width: 100%;
    z-index: 1;
    
    @include tablet-down {
      max-width: rem(744);
    }

    @include small-mobile-up {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @include small-mobile {
      max-width: 90%;
    }

    &_wrap {
      background: linear-gradient(161.19deg, #fff 0.96%, #ccc 24.24%, #fff 54.65%, #ccc 80.67%, #fff 99.03%);
      padding: 4px;
      padding: rem(42) rem(100);
      
      @include tablet-down {
        padding: rem(32) rem(18);
      }
    }
  }

  &_heading {
    margin: auto;
    max-width: rem(580);
  }

  &_content {
    margin-top: rem(32);
  }

  &_info {
    margin-top: rem(16);

    &_item {
      &:not(:first-child) {
        margin-top: rem(8);
      }
    }
  }

  &_slider {
    background: $mountbatten-pink-linear2;
    border-radius: rem(55);
    margin: rem(24) auto rem(34);
    max-width: rem(585);
    padding: 5px;

    &_item {
      @include adjust-flex(center, center);
    }

    @include mobile-down {
      max-width: rem(300);
    }

    &_wrap {
      background: linear-gradient($white, $white, $white, $battleship-gray, $spanish-gray);
      border-radius: rem(55);
      padding-bottom: 4px;
      padding-top: 4px;
      width: 100%;
    }

    .slick-list {
      padding-bottom: 5px;
    }

    .o-carousel_arrow {
      top: 57%;
    }

    .o-carousel_arrow_next {
      right: -10%;
    }

    .o-carousel_arrow_prev {
      left: -10%;
    }
  }

  &_list {
    display: flex;
    flex-wrap: wrap;
    margin: rem(14) rem(-4) rem(-8);
    max-height: rem(240);
    overflow: auto;
    padding: 5px;
    @include scroll-bars;
    @include desktop-down {
      max-height: rem(208);
    }
    @include small-mobile {
      margin: rem(12) auto rem(10);
      max-height: rem(336);
      max-width: rem(293);
    }

    &_item {
      @include adjust-flex(center, center);
      flex: 0 0 16%;
      margin-bottom: rem(8);
      max-width: 16%;
      padding-left: rem(4);
      padding-right: rem(4);

      @include small-mobile {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }

  &_contact{
    @include mobile-down{
      max-width: rem(320);
      margin-left: auto;
      margin-right: auto;
    }
  }

  .o-numberBannerLayout_background {
    @include small-mobile {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .o-numberBannerLayout {
    @include small-mobile {
      padding-bottom: 20%;
      padding-top: 15%;
      @include adjust-flex(center, center);
    }
  }
}
