.t-prizeNotify {
  $root: &;
  background: transparent url('~assets/images/prizes/bg-prizeTable.jpg') no-repeat center/100% auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 100%;
  background-size: cover;

  @include tablet-down {
    padding-bottom: rem(20);
  }

  &-special {
    background-image: url('~assets/images/prizes/bg-special-prize.jpg');

    #{$root}_content_flex {
      align-items: center;
      display: flex;
      margin-top: rem(16);
    }

    #{$root}_number {
      margin: 0;
      width: 42%;
      flex-shrink: 0;
      max-width: unset;

      .number {
        @include font-base(98, 120);
        margin-top: rem(15);

        @include tablet-down {
          @include font-base(40, 76);
        }
        
        &.small {
          font-size: rem(66);

          @include tablet-down {
            font-size: rem(32);
          }
        }
      }
    }

    #{$root}_info {
      margin-left: -9.3%;
      width: 100%;
      max-width: rem(816);

      &_name {
        text-align: left;
        @include font-base(30, 40);
      }

      .a-text-32x38 {
        @include tablet-down {
          @include font-base(18, 26);
        }
      }
    }
  }

  &_background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-1;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &_header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: rem(16) rem(24);
    position: relative;
    z-index: $z-1;
  }

  &_logo {
    max-width: 18%;
    width: 100%;
  }

  &_clplogo {
    max-width: 10%;
    width: 100%;
  }

  &_content {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: $font-utmavo;
    height: 100%;
    padding: 0 rem(16) rem(16);
    position: relative;
    z-index: $z-1;
    margin-top: rem(80);

    @include tablet-down {
      padding-top: 0;
      margin-top: rem(60);
    }

    &_wrap {
      width: 100%;
    }

    .o-container {
      @include tablet-down {
        max-width: 100% !important;
      }
    }
  }

  &_number {
    margin: rem(-40) auto;
    max-width: rem(481);
    position: relative;

    @include desktop-down {
      max-width: rem(360);
    }

    @include tablet-down {
      margin: rem(-16) auto;
      max-width: rem(240);
    }

    .number {
      // color: $pigment;
      font-weight: 700;
      left: 50%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      // background-clip: text;
      // background-image: linear-gradient(101deg, #7A4D22 0%, #EFE8A7 31.25%, #C2A36B 53.94%, #CAB66E 72.41%, #B98300 92.32%);
      color: #efe8a7;
      @include font-base(140, 120);
      margin-top: rem(16);

      @include tablet-down {
        @include font-base(80, 100);
      }
      &.small {
        font-size: rem(116);
      }
    }
  }

  &_info {
    &_name {
      color: $white;
      font-weight: 700;
      text-align: center;
      @include font-base(36, 40);

      @include tablet-down {
        @include font-base(24, 32);
      }

      span {
        text-transform: uppercase;
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        @include font-base(68, 76);

        @include tablet-down {
          @include font-base(40, 52);
        }
      }
    }

    &_address {
      margin-top: rem(20);

      @include tablet-down {
        margin-top: rem(13);
      }
    }

    &_area {
      margin-top: rem(12);
    }

    &_shopname {
      &-special {
        filter: brightness(1.5);
        background-image: linear-gradient(101deg, #DE9200 0%, #FEEDB3 31.25%, #DE9200 53.94%, #FEEDB3 72.41%, #DE9200 92.32%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: rem(64) !important;
        display: inline-block;
        padding-top: rem(12);

        @include tablet-down {
          font-size: rem(52) !important;
        }
      }
    }
  }

  &_price {
    @include tablet-up {
      margin-top: rem(-20);
    }

    .a-heading-h3 {
      filter: brightness(1.5);
      @include font-base(32, 47);

      @include tablet-up {
        @include font-base(100, 130);
      }
    }
  }

  &_heading {
    display: flex;
    justify-content: center;

    &.special {
      max-width: rem(550);
      margin: auto;

      @include tablet-down {
        max-width: rem(250);
      }

      img {
        width: 100%;
      }
    }
  }
}