.t-guide {
  margin-top: rem(80);

  a {
    color: $pigment;
  }

  @include tablet-down {
    padding: 0 rem(12);
  }

  &_inner {
    // background: $bg-guide-linear;
    border-radius: rem(20);
    min-height: rem(200);
    padding: rem(40) rem(48) rem(80) rem(48);
    position: relative;

    @include tablet-down {
      padding: 0 rem(12) rem(40) rem(12);
    }
  }

  &_item {
    position: relative;

    &+& {
      margin-top: rem(92);

      @include tablet-down {
        margin-top: rem(76);
      }
    }
  }

  &_titleGroup {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &_title {

    .m-headingSection {
      @include mobile-down {
        &::before {
          background-size: auto;
        }
      }
    }

    @include tablet-up {
      &::before {
        background-image: url('~assets/images/system/bg_guide_top.svg');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        height: rem(107);
        left: 0;
        position: absolute;
        top: rem(34);
        width: rem(312);
      }
    }

    @include tablet-down {
      margin-top: rem(-36);
    }

    @include mobile-down {
      width: 100%;
    }

    .m-headingSection {
      span {
        @include mobile-down {
          max-width: rem(238);
        }
      }
    }

    .a-button {
      @include tablet-down {
        height: auto;
        max-width: rem(328);
      }
    }
  }

  &_content {
    margin-top: rem(30);

    @include tablet-up {
      margin-left: rem(-6);
      margin-top: rem(50);
    }

    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      align-items: flex-start;
      display: flex;
      position: relative;

      &:not(:first-child) {
        margin-top: rem(16);
      }

      &::before {
        background-image: url('~assets/images/system/bullet_guide.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        flex: 0 0 auto;
        height: rem(16);
        margin-right: rem(12);
        margin-top: rem(10);
        width: rem(16);

        @include tablet-down {
          margin-top: rem(4);
        }
      }
      .a-text {
        @include tablet-down {
          margin-top: rem(4);
        }
      }
    }
  }
}