$width-left-side: 68vw;
@mixin width-left-side {
  width: 68vw;
  @include desktop-down {
    width: 63vw;
  }
}

.t-livestream {
  $root: &;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100vw;

  &-fullscreen {
    display: block;
    #{$root}_video {
      border-radius: 0 !important;
      height: 100vh;
      width: 100vw;

      &_live {
        height: 100%;
      }
    }
    #{$root}_prize {
      transform: translateX(100%);
    }
    #{$root}_sound,
    #{$root}_fullscreen {
      bottom: rem(7);
    }
    #{$root}_interaction {
      right: 7%;
      @include tablet-up {
        background-color: transparent;
        bottom: rem(4);
        padding: 0;
        position: absolute;
        right: 4%;
      }
    }

    .o-tfcplayer_liveIcon {
      bottom: 1%;
      @include tablet-down {
        bottom: 2%;
        left: 5%;
      }
    }
  }

  &_interaction {
    text-align: right;
    @include tablet-up {
      background-color: $white;
      padding: rem(10) rem(16);
    }
    @include tablet-down {
      bottom: rem(4);
      color: $white;
      position: absolute;
      right: 9%;
    }

    .o-emoji {
      @include tablet-down {
        background-color: transparent;
        box-shadow: unset;
        padding: 0;
      }
    }

    &_floating {
      bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      width: 100%;

      &_item {
        animation: fadeInUpBig $transition-long forwards;
      }
    }
  }

  &_video {
    background-color: $black;
    height: 74vh;
    overflow: hidden;
    position: relative;
    transition: $transition-long ease;
    z-index: 2;
    @include width-left-side;
    @include tablet-down {
      height: 62vh;
    }

    @include tablet-up {
      border-bottom-left-radius: rem(10);
      border-bottom-right-radius: rem(10);
    }

    &_live {
      height: 100%;
      width: 100%;
      @include tablet-up {
        height: calc(100% - 58px);
      }
    }

    .video-js {
      height: 100% !important;
      padding-top: 0 !important;
      width: 100%;
    }
  }

  &_prize {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    padding: rem(24) rem(20) rem(24) rem(10);
    position: absolute;
    right: 0;
    top: 0;
    transition: $transition-long ease;
    width: 32vw;
    @include desktop-down {
      width: 37vw;
    }

    @include tablet-down {
      padding: rem(18) rem(8);
    }

    &_title {
      span {
        @include break-max(670px) {
          display: block;
        }
      }
    }

    &_list {
      margin-top: rem(26);

      @include desktop-down {
        margin-top: rem(18);
      }
    }

    &_item {
      margin-bottom: rem(16);
      @include desktop-down {
        margin-bottom: rem(12);
      }
      @include tablet-down {
        margin-bottom: rem(8);
      }
    }

    .o-table_header_cell:first-child,
    .o-table_header_cell:last-child,
    .o-table_body_cell:first-child,
    .o-table_body_cell:last-child {
      text-align: center;
    }
    .o-table_body_cell {
      .a-text{
        text-transform: uppercase;
      }
    }
  }

  &_fullscreen {
    align-items: center;
    bottom: rem(10);
    cursor: pointer;
    display: flex;
    height: rem(24);
    justify-content: center;
    position: absolute;
    right: rem(16);
    width: rem(24);
    z-index: 5;
    @include tablet-up {
      bottom: rem(57);
    }
    // @include break-min($standard-viewport) {
    //   bottom: 32 * $w;
    //   height: 30 * $w;
    //   right: 16 * $w;
    //   width: 30 * $w;
    // }
  }

  &_sound {
    align-items: center;
    bottom: 2px;
    cursor: pointer;
    display: flex;
    height: rem(24);
    justify-content: center;
    left: rem(16);
    position: absolute;
    width: rem(24);
    z-index: 5;
    @include tablet-down {
      bottom: rem(6);
    }
    // @include break-min($standard-viewport) {
    //   bottom: 32 * $w;
    //   height: 30 * $w;
    //   left: 16 * $w;
    //   width: 30 * $w;
    // }
  }

  &_control {
    background-color: $white;
    height: calc(26vh - 8px);
    margin-top: rem(8);
    overflow: auto;
    padding: rem(21) rem(16) rem(12);
    position: relative;
    z-index: 1;
    @include width-left-side;
    @include scroll-bars;
    @include tablet-up {
      border-top-left-radius: rem(10);
      border-top-right-radius: rem(10);
    }
    @include tablet-down {
      height: 38vh;
      margin-top: 0;
      padding: rem(7) rem(6);
    }

    &_top {
      @include adjust-flex(space-between, center);

      &_left {
        .a-heading {
          @include tablet-down {
            @include font-base(16, 21);
          }
        }

        .a-text {
          @include tablet-down {
            @include font-base(12, 16);
          }
        }
      }
    }

    &_button {
      max-width: rem(200);

      @include tablet-down {
        max-width: rem(152);
      }

      .a-button-sm {
        padding: rem(12) rem(10);

        span {
          @include font-base(12, 16);
          @include tablet-down {
            @include font-base(9, 14);
          }
        }
      }
    }

    &_slider {
      background: $mountbatten-pink-linear2;
      border-radius: rem(55);
      margin: rem(12) auto rem(16);
      max-width: 84%;
      padding: 5px;

      @include tablet-down {
        max-width: rem(334);
      }

      &_wrap {
        background: linear-gradient($white, $white, $white, $battleship-gray, $spanish-gray);
        border-radius: rem(55);
        padding-bottom: 4px;
        padding-top: 4px;
        width: 100%;
      }

      .slick-list {
        padding-bottom: 5px;
      }
  
      .o-carousel_arrow {
        top: 57%;
      }
  
      .o-carousel_arrow_next {
        right: -10%;

        @include tablet-down {
          right: -12%;
        }
      }

      .o-carousel_dots {
        bottom: rem(-32);

        @include tablet-down {
          bottom: rem(-20);
        }
      }
  
      .o-carousel_arrow_prev {
        left: -10%;

        @include tablet-down {
          left: -12%;
        }
      }
    }

    .m-ball {
      p{
        @include tablet-up{
          margin-top: rem(10);
        }
      }
    }
  }

  &_result {
    $result: &;
    background-color: $anti-flash-white2;
    height: 100%;
    padding: rem(14) rem(12);
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: $transition-long ease;
    width: rem(270);
    z-index: 8;

    @include desktop-up {
      width: rem(420);
    }

    &_icon {
      background-color: rgba($black, 0.6);
      border-bottom-left-radius: rem(16);
      border-top-left-radius: rem(16);
      cursor: pointer;
      height: rem(36);
      left: rem(-34);
      position: absolute;
      top: rem(8);
      width: rem(34);
      @include desktop-up {
        height: rem(46);
        left: rem(-44);
        width: rem(44);
      }

      &::before {
        border-bottom: 2px solid $white;
        border-left: 2px solid $white;
        content: '';
        height: 8px;
        left: rem(15);
        position: absolute;
        top: rem(12);
        transform: rotate(45deg);
        transition: $transition ease;
        width: 8px;
        @include desktop-up {
          border-width: 3px;
          height: rem(10);
          left: rem(17);
          top: rem(15);
          width: rem(10);
        }
      }
    }

    &_number {
      background-color: rgba($white, 0.86);
      border-radius: 8px;
      display: flex;
      flex-wrap: wrap;
      padding: 8px;

      &_item {
        flex: 0 0 25%;
        margin-bottom: 4px;
        max-width: 25%;
        text-align: center;
      }

      .m-ball-45 {
        @include desktop-up {
          height: rem(70);
          width: rem(70);
        }

        .a-text {
          @include desktop-up {
            @include font-base(28, 24);
          }
        }
      }
    }

    &_table {
      &_item {
        margin-bottom: rem(8);
      }
    }

    &_tab {
      margin-bottom: 8px;
    }

    &_label {
      cursor: pointer;
      padding: rem(8);
      @include desktop-up {
        padding: rem(10) rem(12);
      }

      &.active {
        background: rgba($white, 0.86);
        border-radius: 8px;
        box-shadow: 0 4px 4px rgba($black, 0.02);
        padding: 8px;
      }

      .a-text {
        @include desktop-up {
          @include font-base(18, 22);
        }
      }
    }

    &_content {
      height: calc(100% - 64px);
      overflow: auto;
      @include scroll-bars;
    }

    &.visible {
      background-color: rgba($anti-flash-white2, 0.86);
    }

    &.open {
      transform: translateX(0);
      #{$result}_icon {
        &::before {
          left: rem(13);
          transform: rotate(225deg);
        }
      }
    }
  }
}

.theoplayer-skin {
  max-height: 100vh !important;
}

.theoplayer-skin .theo-control-bar-shadow {
  background: unset !important;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  opacity: 1 !important;
}

.theoplayer-skin .vjs-fullscreen-control,
.theoplayer-skin .theo-settings-control-button {
  display: none !important;
}

.theoplayer-skin .vjs-big-play-button {
  display: none !important;
}

.theoplayer-skin .vjs-progress-control {
  display: none !important;
}

.theoplayer-skin .vjs-play-control {
  display: none !important;
}

.theoplayer-skin .vjs-time-control {
  display: none !important;
}

.theoplayer-skin li[aria-label='Open the video speed settings menu'] {
  display: none !important;
}

​.video-js .vjs-fullscreen-control {
  display: none !important;
}


.float-container {
  // height: 74vh !important;
  // width: 68vw !important;
  z-index: 1000;
}
