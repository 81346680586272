.t-policyPopup {
  &_list {
    ul {
      list-style: none;
      padding-left: 0;

      li {
        padding-left: rem(14);
        position: relative;

        &::before {
          background-color: $pigment;
          border-radius: 50%;
          content: '';
          height: 8px;
          left: 0;
          position: absolute;
          top: 7px;
          width: 8px;
        }
      }

      li:not(:first-child) {
        margin-top: rem(14);

        @include tablet-up {
          margin-top: rem(20);
        }
      }
    }
  }

  &_link {
    text-decoration: underline;
    color: $royal-azure;
    font-weight: 600;
  }
}