.p-schedule {
  padding-bottom: rem(28);
  background: linear-gradient(
    90deg,
    #0189d0 2.24%,
    #0181c9 7.34%,
    #015ea9 34.87%,
    #004492 60.36%,
    #003484 83.81%,
    #002f7f 102.16%
  );

  &_banner_wrapper {
    position: relative;
  }

  &_banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &_main,
  &_invitation {
    margin: 0 auto rem(32);
    padding: 0 rem(80);
    color: #ffffff;
    font-family: 'UTM Avo', sans-serif;
    position: relative;

    @include mobile-down {
      padding: 0 rem(16);
    }
  }

  &_main {
    margin-bottom: 0;

    @include mobile-down {
      margin-top: rem(-42);
    }
  }

  &_invitation {
    margin-bottom: rem(80);
  }

  &_main_border {
    clip-path: polygon(
      0 0,
      25% 0,
      25% 2%,
      75% 2%,
      75% 0,
      100% 0,
      100% 85%,
      100% 100%,
      85% 100%,
      15% 100%,
      0 100%,
      0% 85%
    );
    border-radius: rem(24);
    border: rem(2) solid #33c0d1;
    padding: rem(24);

    @include mobile-down {
      clip-path: polygon(
        0 0,
        14% 0,
        20% 2%,
        80% 2%,
        86% 0,
        100% 0,
        100% 85%,
        100% 100%,
        85% 100%,
        15% 100%,
        0 100%,
        0% 85%
      );
      border-radius: rem(24);
      border: rem(2) solid #33c0d1;
      padding: rem(24);
    }
  }

  &_main td {
    padding: rem(4) 0;
  }

  &_main h2 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem(32);
    text-wrap: nowrap;
    margin-top: 0;

    @include desktop-down {
      font-size: rem(24);
    }

    @include mobile-down {
      font-size: rem(18);
      margin-top: 0;
    }
  }

  &_main .table-heading h5 {
    width: fit-content;
    border: rem(2) solid #33c0d1;
    margin: 0;
    padding: rem(4) rem(12);
    border-radius: rem(8);
    font-weight: 600;
  }

  &_main .table-heading:first-child {
    min-width: rem(200);

    @include mobile-down {
      min-width: rem(100);
    }
  }

  &_main tr {
    vertical-align: top;
    font-size: rem(24);

    @include mobile-down {
      font-size: rem(14);
    }
  }

  &_invitation {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_invitation p {
    font-size: rem(42);

    @include mobile-down {
      font-size: rem(24);
    }
  }

  &_invitation_title {
    max-width: rem(500);
    height: rem(80);
    border: none;
    font-weight: 600;
    font-size: rem(40);
    box-shadow: none;
    outline: none;
    background: transparent url('~assets/images/button-frame-1.png') no-repeat center/100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(32);
    padding: 0 rem(24);

    @include mobile-down {
      font-size: rem(24);
      padding: 0;
      width: rem(300);
      height: rem(60);
      margin-top: rem(30);
      border: none;
      font-weight: 600;
      box-shadow: none;
      outline: none;
      background: transparent url('~assets/images/button-frame-1.png') no-repeat center/100% auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem(32);
    }
  }

  &_code {
    width: rem(500);
    height: rem(500);
    aspect-ratio: 1;
    background-color: #ffffff;

    @include mobile-down {
      width: rem(240);
      height: rem(240);
    }

    &_qr {
      padding: rem(24);

      @include mobile-down {
        padding: rem(16);
      }
    }
  }

  &_store {
    margin-top: rem(30);
  }

  &_contact {
    width: rem(430);
    height: rem(60);
    font-size: rem(24);
    background: transparent url('~assets/images/button-frame-2.png') no-repeat center/100% auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'UTM Avo', sans-serif;
    margin: auto;

    @include mobile-down {
      width: rem(300);
      height: rem(40);
      font-size: rem(16);
    }
  }

  &_error {
    margin-top: rem(30);
    text-align: center;
    font-size: rem(42);
    color: $red;

    @include mobile-down {
      font-size: rem(24);
    }
  }
}
