.t-showContent {
  padding-top: rem(127);

  @include tablet-down {
    padding-top: rem(40);
  }

  .m-sectionTitle_title {
    z-index: 5;
  }

  &_wrapper {
    position: relative;
    border-radius: rem(20);
    overflow: auto;
    padding: rem(72) 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(72);
      background-image: $show-content-linear;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: rem(72);
      background-image: $show-content-linear-bottom;
    }

    @include tablet-down {
      padding: rem(56) 0;
    }

    @include mobile-down {
      padding: rem(56) 0;
    }
  }

  &_table {
    position: relative;
    background-color: $white;
    border-collapse: collapse;
    width: 100%;
    z-index: 2;
  }

  &_cell {
    border: 1px solid #ddd;
    border-right: 0;
    min-width: rem(250);
    padding: rem(24);

    @include tablet-down {
      min-width: rem(150);
    }

    @include mobile-down {
      min-width: rem(134);
    }

    &:first-child {
      border-right: 2px solid $pale-golden-rod;
    }

    @include tablet-down {
      padding: rem(16);
    }

    @include mobile-down {
      padding: rem(12);
    }

    &.time {
      padding-left: rem(50);
      padding-right: rem(50);

      @include tablet-down {
        padding-left: rem(32);
        padding-right: rem(32);
      }

      @include mobile-down {
        padding-left: rem(12);
        padding-right: rem(12);
      }

    }
  }

  &_row {
    &:nth-child(even) {
      background-image: $table-event-linear;
    }

    border-bottom: 2px solid $pale-golden-rod;

    &-header {
      background-image: $table-header-linear !important;
      border: 2px solid $pale-golden-rod;
    }
  }
}