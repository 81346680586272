.t-numberWelcome {
  position: relative;

  @include desktop-up {
    background-image: url('~assets/images/bg-forgetpassword.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &_panel {
    background: linear-gradient(to left, #97858d, #e9e9e9, #9f9f9f, #efefef, #767676, #efefef, #a7a7a7, #ebebeb);
    max-width: 85%;
    padding: 4px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include desktop-up {
      max-width: 1203px;
    }

    @include small-mobile-up {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &_border {
      background: linear-gradient(161.19deg,
          #fff 0.96%,
          #ccc 24.24%,
          #fff 54.65%,
          #ccc 80.67%,
          #fff 99.03%);
      padding: rem(30) rem(40);

      @include mobile-down {
        padding: rem(32) rem(11);
      }

      // @include mobile-down {
      //   height: rem(400);
      //   overflow: auto;
      // }
    }
  }

  &_heading {

    .a-heading-h3,
    .a-text {
      margin-top: rem(8);
    }

    .a-heading-h2 {
      @include small-mobile {
        font-size: rem(19);
      }
    }

    .a-text {
      @include small-mobile {
        font-size: rem(14);
      }
    }
  }

  &_label {
    margin: rem(23) auto 0;
    max-width: rem(228);
    position: relative;

    @include desktop-up {
      &::before {
        background-image: url('~assets/images/curve.png');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        height: rem(107);
        position: absolute;
        right: 85%;
        top: 5%;
        width: rem(472);
      }
    }
  }

  &_rule {
    margin-top: rem(26);

    @include mobile-down {
      margin-top: rem(30);
    }

    &_item {
      padding-left: rem(26);
      position: relative;

      @include mobile-down {
        padding-left: rem(20);
      }

      &:not(:first-child) {
        margin-top: rem(12);
      }

      &::before {
        background-image: url('~assets/images/system/bullet_guide.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: inline-block;
        height: rem(16);
        left: 0;
        position: absolute;
        top: 3px;
        width: rem(16);

        @include tablet-down {
          margin-top: rem(4);
        }

        @include mobile-down {
          height: rem(12);
          width: rem(12);
        }
      }
    }

    .special {
      @include mobile-down {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @include tablet-up {
        display: flex;
      }

      .label {
        width: rem(150);

        @include mobile-down {
          width: rem(100);

          .a-text {
          	font-size: rem(14);
            line-height: rem(20);
          }
        }
      }
    }

    ul {
      list-style: none;
      max-width: rem(730);
      padding-left: 0;

      @include tablet-up {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: rem(98);
        margin-left: rem(-8);
        margin-right: rem(-8);
      }

      li {
        margin-top: rem(5);
        width: 100%;
        @include mobile-down {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        @include tablet-up {
          flex: 0 0 22px;
          max-width: 50%;
          padding-left: rem(8);
          padding-right: rem(8);
        }
      }
    }

    .label {
      display: inline-block;
      width: rem(150);

      @include mobile-down {
        width: rem(100);

        .a-text {
          font-size: rem(14);
          line-height: rem(20);
        }
      }
    }

    .value {
      display: inline-block;

      @include mobile-down {
        .a-text {
          font-size: rem(14);
          line-height: rem(20);
        }
      }
    }
  }

  &_button {
    margin: rem(24) auto 0;
    max-width: rem(296);
  }

  .o-numberBannerLayout_background {
    @include small-mobile {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .o-numberBannerLayout {
    @include small-mobile {
      padding-bottom: rem(20);
      padding-top: 15%;
      @include adjust-flex(center, center);
    }
  }
}