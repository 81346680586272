.a-image {
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  transition: $transition-long ease;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-normal {
    padding-bottom: 0 !important;

    img {
      left: unset;
      max-width: 100%;
      position: static;
      top: unset;
      width: unset;
    }
  }

  &-1x1 {
    @include aspect-ratio;
  }

  &-4x3 {
    @include aspect-ratio(4, 3);
  }

  &-16x9 {
    @include aspect-ratio(16, 9);
  }

  &-170x96 {
    @include aspect-ratio(170, 96);
  }

  &-popupVideo {
    @include aspect-ratio(5907, 3611);
  }

  &-289x240 {
    @include aspect-ratio(289, 240);
  }

  &-436x302 {
    @include aspect-ratio(436, 302);
  }

  &-726x356 {
    @include aspect-ratio(726, 356);
  }

  &-1366x768 {
    @include aspect-ratio(1366, 768);
  }

  &-1366x768 {
    @include aspect-ratio(1366, 768);
  }

  &-logo {
    @include aspect-ratio(172, 162);
  }

  &-505x397 {
    @include aspect-ratio(505, 397);
  }

  &-142x83 {
    @include aspect-ratio(142, 83);
  }

  &-375x812 {
    @include aspect-ratio(375, 812);

    @include mobile-down {
      &::before {
        padding-bottom: 157.53333%;
      }
    }
  }

  &-2736x1216 {
    @include aspect-ratio(2736, 1216);
  }

  &-508x52 {
    @include aspect-ratio(508, 52);
  }

  &-173x24 {
    @include aspect-ratio(173, 24);
  }

  &-234x23 {
    @include aspect-ratio(234, 23);
  }

  &-166x62 {
    @include aspect-ratio(166, 62);
  }

  &-574x550 {
    @include aspect-ratio(574, 550);
  }

  &-277x100 {
    @include aspect-ratio(277, 100);
  }

  &-1360x768 {
    @include aspect-ratio(1360, 768);
  }

  &-567x308 {
    @include aspect-ratio(567, 308);
  }
}