.t-newsEventsList {
  background: $egyptian-blue-linear;
  overflow: hidden;
  padding-bottom: calc(107 / #{$width-container-value} * 100%);
  position: relative;

  @include tablet-down {
    padding-bottom: rem(40);
  }

  @include tablet-up {
    // &::before,
    // &::after {
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   content: "";
    //   height: calc(1072 / #{$width-container-value} * 100%);
    //   max-width: rem(190);
    //   position: absolute;
    //   width: calc(190 / #{$width-container-value} * 100%);
    //   z-index: 1;
    // }

    &::before {
      background-image: url("~assets/images/bg-no-sparkles.png");
      left: 0;
      top: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
    }

    // &::after {
    //   background-image: url('~assets/images/system/bg_right.png');
    //   bottom: 0;
    //   right: 0;
    // }
  }

  &_heading {
    margin: auto;
    max-width: rem(388);

    @include mobile-down {
      max-width: rem(308);
    }
  }

  &_menu {
    background: $menu-linear;
    padding: rem(16) rem(5);
    position: relative;
    z-index: 1;

    &_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    &.fixed {
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: $z-99;
    }

    @include tablet-down {
      display: none;
    }

    .a-button {
      flex: 0 0 20%;
      margin-bottom: rem(6);
      max-width: rem(250);
      padding: 0 rem(10);

      &:not(:first-of-type) {
        margin-left: rem(10);
      }

      @include tablet-up {
        min-width: rem(255);
      }

      @include break-max(1360px) {
        flex: unset;
        max-width: rem(180);
        min-width: auto;
      }

      span {
        @include font-base(16, 24);
        text-transform: uppercase;

        @include break-max(1360px) {
          @include font-base(12, 24);
        }
      }
    }
  }

  &_wrapper {
    margin-top: rem(40);
    position: relative;

    @include tablet-up {
      margin-top: rem(80);
    }
  }

  &_heading {
    .a-button {
      margin: auto;
      max-width: rem(340);

      @include tablet-down {
        max-width: rem(286);
        padding-bottom: rem(14);
        padding-top: rem(14);
      }
    }
  }

  &_content {
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(80);
    max-width: rem(1032);

    @include tablet-down {
      margin-top: rem(25);
    }
  }

  .o-events {
    &:not(:first-of-type) {
      margin-top: rem(70);

      @include tablet-down {
        margin-top: rem(35);
      }
    }
  }

  &_carousel {
    .swiper-slide {
      height: auto;
    }
  }
}
