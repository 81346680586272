.p-instruction {
  background-color: $black;
  overflow: hidden;
  position: relative;
  @include tablet-up {
    height: 100vh;
    // min-height: rem(768);
  }

  &-resize {
    // min-height: rem(650);

    // .t-instruction {
    //   margin: auto;
    //   width: 78%;
    // }
  }

  // .t-instruction {
  //   margin: auto;
  //   @include desktop-up {
  //     width: 85%;
  //   }

  //   @include tablet {
  //     width: 68%;
  //   }

  //   @include break-max(500px) {
  //     height: 100vh;
  //   }
  // }

  // .t-instruction_background {
  //   height: 100%;
  //   width: 100%;

  //   @include break-max(500px) {
  //     left: 0;
  //     position: absolute;
  //     top: 0;
  //   }

  //   .a-image {
  //     padding-bottom: 0;

  //     img {
  //       position: static;
  //     }
  //   }
  // }
}
