.t-pickingBall {
  $root: &;
  background-image: url('~assets/images/bg-welcome.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &-resize {
    #{$root}_desc {
      &.p6 {
        margin-top: rem(9);
      }
    }

    #{$root}_title {
      max-width: rem(364);
    }
    #{$root}_username {
      @include font-base(16, 20);
    }
    #{$root}_flex {
      height: rem(60);
    }
    #{$root}_list .m-ball {
      margin-top: rem(10);
    }
    #{$root}_notice {
      margin-top: rem(10);
    }

    #{$root}_submit {
      padding-top: rem(14);
    }

    .u-mt-21 {
      margin-top: rem(14);
    }
  }

  &_head {
    background: linear-gradient(0deg, #140c40 -0.03%, #191d5b 18.72%, #243e90 49.99%, #213480 63.21%, #181954 89.23%, #140c40 100%);
  }

  &_flex {
    @include adjust-flex(flex-end, center);
    height: rem(77);
    position: relative;

    @include tablet-down {
      flex-direction: column;
      height: rem(65);
      @include adjust-flex(center, center);
    }
  }

  &_title {
    left: 50%;
    max-width: rem(508);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include tablet-down {
      left: auto;
      margin-left: auto;
      margin-right: auto;
      max-width: rem(240);
      position: relative;
      top: auto;
      transform: none;
    }
  }

  &_username {
    color: $white;
    padding-right: rem(24);
    text-align: center;
    @include font-base(19, 26);

    @include tablet-down {
      padding: 0;
      @include font-base(14, 19);

      br {
        display: none;
      }
    }
  }

  &_desc {
    color: $white;
    font-family: $font-myriad;
    font-weight: 400;
    text-align: center;
    @include font-base(20, 24);

    @include tablet-down {
      @include font-base(14, 17);
    }

    &.hide-br {
      @include tablet-up {
        br {
          display: none;
        }
      }
    }

    &.p1 {
      .a-text:last-child {
        @include small-mobile {
          display: block;
        }
      }
    }

    &.p1,
    &.p2,
    &.p3 {
      margin-top: rem(8);
    }

    &.p6 {
      color: #fcdb7a;
      font-weight: 700;
      margin-top: rem(24);

      @include tablet-down {
        margin-top: rem(16);
      }
    }

    &.p7 {
      margin-top: rem(21);

      @include tablet-down {
        margin-top: rem(16);
      }
    }

    &.p8 {
      color: #eb5757;
      font-style: italic;
      font-weight: 700;
      margin-top: rem(10);

      @include tablet-down {
        margin-top: rem(10);
      }
    }
  }

  &_search {
    margin-top: rem(12);
    @include adjust-flex(center, center);

    &-text {
      margin-right: rem(20);
      max-width: rem(173);
      width: 100%;

      @include tablet-down {
        max-width: rem(120);
      }
    }

    &-input {
      align-items: center;
      display: flex;
      position: relative;

      input {
        background-color: transparent;
        background-image: url('~assets/images/ball/input.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border: 0;
        color: $white;
        font-family: inherit;
        font-size: rem(24);
        height: rem(41);
        outline: none;
        padding-left: rem(48);
        padding-right: rem(48);
        text-align: center;
        width: rem(264);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }

        @include tablet-down {
          font-size: rem(16);
          width: rem(170);
        }
      }

      i {
        left: rem(14);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @include tablet-down {
          height: rem(15);
          width: rem(15);
        }
      }
    }
  }

  &_notice {
    flex-wrap: wrap;
    margin-top: rem(16);
    @include adjust-flex(center, center);

    @include tablet-down {
      flex-direction: column;
    }

    &_wrap {
      @include adjust-flex(center, center);
      flex-wrap: wrap;
    }

    &-item {
      @include adjust-flex(center, center);
      margin-left: rem(56);
      padding-left: 8px;
      padding-right: 8px;

      &:first-of-type {
        margin-left: rem(26);

        @include tablet-down {
          margin-left: 0;
        }
      }

      @include tablet-down {
        margin-left: 0;
        margin-top: rem(10);
      }
    }

    &-ball {
      margin-right: rem(10);
      @include adjust-flex(center, center);

      @include tablet-down {
        margin-right: rem(6);
      }
    }
  }

  &_submit {
    padding-bottom: rem(40);
    padding-top: rem(20);

    @include tablet-down {
      padding-bottom: rem(24);
    }

    .a-button {
      margin: auto;
      width: auto;
    }
  }

  &_list {
    .m-ball {
      margin-top: rem(18);
    }

    .slick {
      &-arrow {
        height: rem(40);
        width: rem(42);

        &::before {
          content: none;
        }

        @include small-mobile {
          height: rem(27);
          width: rem(32);
        }
      }

      &-prev {
        background-image: url('~assets/images/ball/prev.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        left: rem(-64);

        @include small-mobile {
          left: rem(-48);
        }
      }

      &-next {
        background-image: url('~assets/images/ball/next.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        right: rem(-48);

        @include small-mobile {
          right: rem(-40);
        }
      }
    }

    @include desktop-down {
      margin-left: auto;
      margin-right: auto;
      max-width: rem(800);
    }

    @include tablet-down {
      max-width: rem(600);
    }

    @include small-mobile {
      max-width: rem(250);

      .m-ball {
        margin-top: rem(8);
      }
    }
  }

  &_current {
    align-items: center;
    background-image: url('~assets/images/ball/wrap_ball.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(16);
    max-width: rem(604);
    min-height: rem(106);
    padding-left: rem(16);
    padding-right: rem(16);
    width: 100%;

    @include tablet-down {
      max-width: rem(368);
      min-height: rem(80);
    }
    @include mobile-down {
      max-width: rem(320);
      min-height: rem(80);
    }

    &-item {
      flex: 0 0 rem(94);
      padding-left: rem(8);
      padding-right: rem(8);

      @include tablet-down {
        flex: 0 0 rem(45);
        padding-left: rem(6);
        padding-right: rem(6);
      }

      @include mobile-down {
        flex: 0 0 rem(35);
      }

      .m-ball {
        @include mobile-down {
          height: rem(45);
          width: rem(45);
  
          .a-text {
            font-size: rem(16);
          }
        }

        &_wrapper-80 {
          @include mobile-down {
            height: rem(45);
            width: rem(45);
          }
        }
      }
    }
  }

  &_loading {
    background-color: rgba($black, 0.3);
    border-radius: rem(10);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-2;
    @include adjust-flex(center, center);

    img {
      width: rem(90);
    }
  }

  &_filter {
    margin-top: rem(15);
    @include adjust-flex(center, center);
  }
}


.t-selectedBall_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 rem(-4) rem(-8);
  max-height: rem(240);
  overflow: auto;
  @include scroll-bars;

  @include desktop-down {
    max-height: rem(208);
  }
  @include small-mobile {
    margin-left: auto;
    margin-right: auto;
    max-height: rem(320);
    max-width: rem(293);
  }

  &_item {
    flex: 0 0 16%;
    margin-bottom: rem(8);
    max-width: 16%;
    padding-left: rem(4);
    padding-right: rem(4);

    @include small-mobile {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}
