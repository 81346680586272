.t-foyer {
  $root: &;
  background-color: $black;
  height: 100%;
  position: relative;
  @include adjust-flex(center, center);

  &_selfie {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 101;
  }

  &_concept {
    position: relative;

    &.hall {
      #{$root}_concept_layer {
        cursor: pointer;
        height: 63%;
        position: absolute;
        right: 2%;
        top: 18%;
        width: 66%;
        z-index: $z-1;

        @include tablet-down {
          height: 80%;
          right: 2%;
          top: 15%;
          width: 60%;
        }
      }
    }

    &_video {
      position: relative;

      img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }

      video {
        pointer-events: none;
        position: relative;
        z-index: 1;
      }
    }

    &_image {
      img {
        height: 100%;
        width: 100%;
      }
    }

    .t-boothDetail {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &_booth {
    bottom: 10%;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
    width: 100%;
    z-index: 2;
    @include tablet-down {
      bottom: 5%;
    }

    &_title {
      background-color: rgba($pigment, 0.5);
      border-radius: rem(8);
      display: inline-block;
      padding: rem(8) rem(12);
      @include tablet-down {
        padding: rem(4) rem(9);
      }
    }

    &_wrap {
      margin-top: rem(20);
      @include adjust-flex(center, center);
      @include tablet-down {
        margin-top: rem(10);
      }
    }

    &_item {
      cursor: pointer;
      margin-left: rem(10);
      margin-right: rem(10);
      max-width: rem(90);
      @include tablet-down {
        margin-left: rem(8);
        margin-right: rem(8);
        max-width: rem(60);
      }

      img {
        width: 100%;
      }
    }

    &_direction {
      bottom: 8%;
      position: absolute;
      @include tablet-down {
        width: rem(140);
      }
      @include mobile-down {
        width: rem(100);
      }

      .a-button {
        span {
          @include tablet-down {
            @include font-base(16, 20);
          }
          @include mobile-down {
            @include font-base(12, 14);
          }
        }
      }

      &.left {
        left: 2%;
      }

      &.right {
        right: 2%;
      }
    }
  }

  &_boothDetail {
    background-color: $black;
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
    @include adjust-flex(center, center);
  }

  &_menu {
    left: 50%;
    position: absolute;
    top: 2%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
    @include adjust-flex(center, center);

    &_item {
      margin-left: rem(8);
      margin-right: rem(8);

      .a-button {
        opacity: 0.75;

        span {
          @include tablet-down {
            @include font-base(10, 16);
          }
        }
      }
    }
  }

  &_sound {
    align-items: center;
    bottom: 4%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 3%;
    position: absolute;
    z-index: $z-2;

    &.muted {
      animation: zooming 1s infinite;

      p {
        visibility: visible;
      }
    }

    p {
      color: #1c4584;
      font-weight: bold;
      margin-top: rem(8);
      visibility: hidden;
      white-space: nowrap;
      @include tablet-down {
        font-size: rem(12);
        margin-top: rem(4);
      }
      @include mobile-down {
        font-size: rem(10);
      }
    }

    @include tablet-down {
      bottom: 10%;
    }
  }

  &_quiz {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}
