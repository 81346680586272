@mixin square ($val) {
  height: rem($val);
  width: rem($val);
}

.m-ball {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  height: rem(80);
  justify-content: center;
  width: rem(80);

  &-38 {
    @include square(38);

    @include tablet-down {
      @include square(16);
    }
  }

  &-45 {
    @include square(45);

    .a-text {
      @include font-base(16, 20);
    }
  }

  &-80 {
    @include square(80);

    @include tablet-down {
      @include square(54);
    }

    .a-text {
      @include desktop-down {
        @include font-base(20);
      }
    }
  }

  &-72 {
    @include square(72);

    @include tablet-down {
      @include square(64);
    }

    @include small-mobile {
      @include square(58);
    }
  }

  &-103 {
    @include square(103);

    .a-text {
      @include font-base(36, 42);
    }

    @include desktop-down {
      @include square(65);

      .a-text {
        @include font-base(18, 24);
      }
    }

    @include tablet-down {
      @include square(45);

      .a-text {
        @include font-base(18, 24);
      }
    }
  }

  &-110 {
    @include square(110);

    .a-text {
      @include font-base(36, 42);
    }

    @include tablet-down {
      @include square(45);

      .a-text {
        @include font-base(18, 24);
      }
    }
  }

  &-pointer {
    cursor: pointer;
  }

  &-empty {
    background-image: url('~assets/images/numberChoosing/ball-normal.png');
    color: $white;
  }

  &-bingo {
    background-image: url('~assets/images/numberChoosing/bingo-ball.png');
    color: $jasmine2;
  }

  &-active {
    background-image: url('~assets/images/numberChoosing/ball-picking.png');
    color: #7b4318;
  }

  &-disabled {
    background-image: url('~assets/images/numberChoosing/ball-chosen.png');
    color: #333;
  }

  &-all {
    background-image: url('~assets/images/ball/all_ball.svg');
  }
}
