.t-selfie {
  $root: &;
  min-height: 100vh;
  position: relative;
  @include tablet-down {
    height: 100vh;
    min-height: rem(375);
  }

  &_background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $z-1;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &_wrap {
    left: 50%;
    max-width: 1366px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: $z-2;
    @include adjust-flex(center, center);

    @include break-max(1365px) {
      max-width: 90%;
    }

    @include desktop-down {
      max-width: 90%;
    }

    @include tablet-down {
      max-width: 65vw;
    }

    &.resize {
      max-width: 75%;

      #{$root}_border_title {
        font-size: rem(12);
      }
    }
  }

  &_action {
    text-align: center;

    &_item {
      margin-bottom: 24%;
    }
  }

  &_frame {
    margin-left: rem(20);
    width: 100%;
    @include adjust-flex(unset, center);
    @include tablet-down {
      margin-left: rem(12);
    }

    &_image {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      img {
        height: 100%;
        width: 100%;
      }
    }
    
    &_video {
      background-color: #006fb9;
      border-radius: rem(10);
      padding-bottom: 659/878 * 85%;
      // aspect-ratio: 1190/659;
      position: relative;
      width: 100%;

      &.hotfix {
        padding-bottom: 659/878 * 100%;
      }

      video,
      canvas {
        bottom: 0;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
      }

      video {
        object-fit: cover;
      }

      &_border {
        bottom: 0;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        z-index: $z-2;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &_main {
        background-color: #006fb9;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $z-1;
      }
    }
  }

  &_border {
    background-color: rgba($pigment, 0.8);
    border: 5px solid $white;
    flex: 0 0 15%;
    padding-bottom: rem(20);
    @include tablet-desktop {
      flex: 0 0 20%;
    }
    @include tablet-down {
      border-width: 3px;
      padding-bottom: rem(10);
    }

    &_list {
      margin: rem(20) auto rem(-7);
      max-width: 80%;
      @include tablet-down {
        margin-top: rem(10);
        max-width: 85%;
      }

      &_item {
        cursor: pointer;
        margin-bottom: rem(7);
        position: relative;

        &.active {
          &::before {
            opacity: 1;
            visibility: visible;
          }
        }

        &::before {
          background: transparent url('~assets/images/selfie/active-frame.png') no-repeat center/100% auto;
          bottom: rem(-12);
          content: '';
          left: rem(-12);
          opacity: 0;
          position: absolute;
          right: rem(-12);
          top: rem(-12);
          transition: $transition-long ease;
          visibility: hidden;
          
          @include tablet-down {
            bottom: -8%;
            left: -8%;
            right: -8%;
            top: -8%;
          }
        }
      }
    }

    &_title {
      background: $blue-linear-button;
      border-radius: rem(12);
      color: $white;
      margin: rem(-35) auto 0;
      max-width: 85%;
      padding: rem(8) rem(10);
      text-align: center;
      @include tablet-down {
        margin-top: rem(-20);
        padding: rem(4);
        @include font-base(7, 12);
      }

      span {
        display: block;
      }
    }
  }
}
