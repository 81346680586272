.t-checkIn {
  position: relative;
  
  @include small-mobile {
    background-color: #0096d5;
    min-height: 100vh;
    padding-bottom: rem(20);
    @include adjust-flex(center, center);
  }

  &_background {
    @include small-mobile {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .a-image {
      @include tablet-down {
        @include aspect-ratio(700, 1024);
      }
      
      @include mobile-down {
        @include aspect-ratio(375, 576);
      }

      @include small-mobile {
        padding-bottom: 194.53333%;
      }
    }
  }

  &_logo {
    left: 50%;
    position: absolute;
    top: rem(25);
    transform: translateX(-50%);
    transition: $transition-long ease;
    width: rem(282);
    z-index: $z-1;

    @include mobile-down {
      top: rem(16);
      width: rem(163);
    }

    img {
      width: 100%;
    }
  }

  &_form {
    background: linear-gradient(161.19deg, #fff 0.96%, #ccc 24.24%, #fff 54.65%, #ccc 80.67%, #fff 99.03%);
    border: 2px solid $yale-blue;
    box-shadow: 0 0 14px rgba($black, 0.25);
    max-width: rem(468);
    padding: rem(32);
    position: absolute;
    right: rem(70);
    top: 50%;
    transform: translateY(-50%);
    transition: $transition-long ease;
    width: 100%;

    @include break-max(1280px) {
      max-width: rem(370);
      padding: rem(28) rem(20);
    }

    @include tablet-down {
      left: 50%;
      max-width: rem(482);
      top: 41%;
      transform: translateX(-50%);
    }
    
    @include small-mobile {
      margin-top: 58%;
      max-width: 88%;
      padding: rem(24) rem(16);
      position: static;
      transform: none;
      z-index: 2;
    }

    &_title {
      .a-button {
        @include tablet-down {
          padding: rem(52) rem(12);

          span {
            display: block;
          }
        }
      }
    }

    &_desc {
      margin: rem(12) auto 0;
      max-width: rem(357);
    }

    &_wrap {
      margin-top: rem(18);
      position: relative;

      @include small-mobile {
        margin-top: rem(24);
      }
    }

    &_input {
      &:not(:first-child) {
        margin-top: rem(24);
        @include mobile-down {
          margin-top: rem(16);
        }
      }
    }

    &_button {
      margin: rem(20) auto 0;
      max-width: rem(180);
      @include mobile-down {
        margin-top: rem(14);
      }
    }

    &_label {
      border-bottom: 1px solid $pigment;
      margin-top: rem(10);
      padding-bottom: rem(24);

      @include mobile-down {
        padding-bottom: rem(18);
      }

      .a-link {
        text-decoration: underline;
      }
    }

    &_texture {
      position: absolute;
      right: -40%;
      top: -31%;
      width: rem(501);

      @include break-max(1300px) {
        width: rem(460);
      }

      @include tablet-down {
        display: none;
      }

      img {
        width: 100%;
      }
    }
  }

  &_button {
    margin-top: rem(20);

    .a-button {
      &:not(:first-child) {
        margin-top: rem(24);
      }
    }
  }

  &_popup {
    &_name {
      color: $pigment;
      font-family: $font-utmavo;
      text-align: center;
      text-transform: uppercase;
      @include font-base(20, 27);
    }

    &_info {
      font-family: $font-utmavo;
      margin-top: rem(16);

      ul {
        list-style: none;
        padding-left: 0;
        text-align: center;
        
        li {
          color: $jet;
          margin-bottom: rem(12);
          @include font-base(16, 21);
        }

        b {
          color: $pigment;
          font-weight: 700;
        }
      }
    }

    &_video {
      margin: auto;
      max-width: rem(224);

      .wrap {
        background-color: rgba($black, 0.6);
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        transition: $transition-long ease;
        width: 100%;
      }

      video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .o-popup_button {
      display: flex;
      justify-content: space-around;
      margin-top: rem(32);
      max-width: 100%;

      .a-button {
        max-width: rem(150);
      }
    }
  }
}
