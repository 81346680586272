.t-quiz {
  background: transparent url('~assets/images/quiz/bg-quiz.jpg') no-repeat center/100% auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  @include tablet-down {
    padding-bottom: rem(20);
  }

  &_background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: $z-1;
    @include break-max(850px) {
      display: none;
    }
    
    img {
      height: 100%;
      width: 100%;
    }
  }

  &_header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    padding: rem(16) rem(24);
    position: relative;
    z-index: $z-1;
  }

  &_logo {
    max-width: 18%;
    width: 100%;
  }

  &_clplogo {
    max-width: 12%;
    width: 100%;
  }

  &_content {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: $font-utmavo;
    height: 100%;
    justify-content: center;
    padding: rem(16);
    position: relative;
    z-index: $z-1;
    @include tablet-down {
      padding-top: 0;
    }

    &_wrap {
      width: 100%;
    }

    &.lose {
      margin: auto;
      max-width: 76%;

      .a-text {
        margin: 0 auto;
        max-width: rem(655);
      }
    }

    &.start {
      margin: 0 auto;
      max-width: rem(1272);
    }

    .button-flex {
      align-items: center;
      display: flex;
      margin: auto;
      max-width: rem(600);
      width: 100%;
    }

    h1 {
      background: linear-gradient(80.5deg, #c79f33 1.7%, #e4d050 19.22%, #f6e468 25.78%, #f7e692 31.65%, #ebd588 41.98%, #e4d050 47.2%, #c7aa71 71.35%, #ebd468 75.57%, #efde75 82.87%, #d8c046 92.28%, #c79f33 98.53%);
      -webkit-background-clip: text;
      font-size: rem(48);
      margin: 0;
      text-align: center;
      -webkit-text-fill-color: transparent;
      text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);

      @include tablet-down {
        font-size: rem(28);
      }

      @include mobile-down {
        font-size: rem(20);
      }
    }

    .a-text {
      margin-top: rem(32);
    }

    .a-button {
      font-family: $font-sfu-freeway;
      margin: rem(56) auto 0;
      max-width: rem(274);
      text-transform: uppercase;
      
      @include tablet-down {
        margin-top: rem(20);
      }
    }
  }

  &_question {
    background: linear-gradient(90deg, #f2d05f 0%, #ffefb7 50%, #f2d05f 99.99%);
    border-radius: 60px;
    margin: 0 auto;
    padding: rem(12);
    width: 90%;
    @include tablet-down {
      padding: rem(8) rem(10);
    }

    h2 {
      color: $dark-cerulean;
      font-size: rem(32);
      margin: 0;
      text-align: center;
      
      @include tablet-down {
        font-size: rem(20);
      }

      @include mobile-down {
        font-size: rem(16);
      }
    }
  }

  &_answers {
    display: flex;
    flex-wrap: wrap;
    margin: rem(24) auto 0;
    max-width: 60%;
    
    @include tablet-down {
      margin-top: rem(14);
      max-width: 85%;
    }

    @include break-max(670px) {
      max-width: 90%;
    }

    @include mobile-down {
      font-size: rem(8);
    }

    &_item {
      flex: 0 0 50%;
      margin-top: rem(16);
      max-width: 50%;
      padding-left: rem(7);
      padding-right: rem(7);
      @include tablet-down {
        margin-top: rem(8);
      }
    }

    .a-checkbox {
      .a-text {
        color: $white;
        font-family: $font-sfu-freeway;
        margin-top: 0;
        @include tablet-down {
          font-size: rem(16);
          line-height: rem(22);
        }
      }

      &_square {
        border-color: $white;

        &::before {
          border-color: $white !important;
        }
      }
    }
  }

  &_flex {
    align-items: center;
    display: flex;
    width: 95%;
  }

  &_result {
    flex: 0 0 55%;

    h1 {
      font-size: rem(38);

      @include tablet-down {
        font-size: rem(24);
      }

      @include mobile-down {
        font-size: rem(18);
      }
    }

    .a-text {
      span {
        font-weight: bold;
      }
    }
  }

  &_reward {
    flex: 0 0 45%;
  }
}
