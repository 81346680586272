.m-cardnews {
  $root: &;
  height: 100%;
  padding-top: rem(10);
  position: relative;

  &::before {
    background: $white;
    bottom: 0;
    content: '';
    filter: blur(6px);
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 6px);
    z-index: 1;
  }

  &_wrapper {
    position: relative;
    z-index: 2;
  }

  &_dateGroup {
    border-radius: rem(10);
    display: flex;
    margin-bottom: rem(16);
    margin-top: rem(16);
    padding-left: rem(42);
    position: relative;

    @include tablet-down {
      margin-bottom: rem(14);
      margin-top: rem(6);
      padding-left: rem(26);
    }

    &::before {
      align-items: center;
      background: $spanish-gray-linear;
      bottom: 0;
      content: '';
      height: rem(36);
      left: 0;
      position: absolute;
      right: 0;
      top: 2px;
      width: 100%;
      z-index: -1;

      @include tablet-down {
        height: rem(22);
        top: rem(4);
      }
    }
  }

  &_titleDate {
    background-image: url('~assets/images/system/bg_date.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: rem(50);
    margin-top: rem(-7);
    min-width: rem(120);
    position: relative;
    @include adjust-flex(center, center);

    @include tablet-down {
      background-image: url('~assets/images/system/bg_date_mobile.svg');
      height: rem(38);
      margin-top: rem(-6);
      min-width: rem(75);
    }

    &::before {
      background-image: url('~assets/images/system/bullet_date.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      height: rem(22);
      left: rem(-11);
      position: absolute;
      top: rem(-6);
      width: rem(22);
      z-index: -1;

      @include tablet-down {
        left: rem(-12);
        top: rem(-2);
      }
    }

    .a-heading {
      margin-top: rem(4);

      @include tablet-down {
        @include font-base(16, 21);
      }
    }
  }

  &_date {
    margin-top: rem(7);
    padding-left: rem(24);

    @include tablet-down {
      padding-left: rem(6);
      margin-top: rem(4);
    }
  }

  &_description {
    &_content {
      .a-text {
        @include mobile-down {
          @include font-base(16, 22);
        }
      }
    }
  }

  .a-heading {
    @include tablet-down {
      @include font-base(16, 28);
    }
  }

  &_content {
    padding: 0 rem(32) rem(38) rem(32);

    @include tablet-down {
      padding: 0 rem(8) rem(18) rem(8);
    }

    @include mobile-down {
      padding: 0 0 rem(18) rem(8);
    }
  }

  &_item {
    display: flex;
    max-width: rem(450);

    @include tablet-down {
      max-width: 100%;
    }

    &+& {
      margin-top: rem(24);

      @include mobile-down {
        margin-top: rem(12);
      }
    }

    .a-icon {
      flex: 0 0 auto;
      margin-right: rem(22);

      @include tablet-down {
        @include size(22, 22);
        margin-right: rem(6);
      }
    }

    &:first-child {
      #{$root}_description_content {
        @include tablet-down {
          min-height: rem(100);
        }

        @include mobile-down {
          min-height: rem(154);
        }
      }
    }
  }
}