.p-awardAnnouncement {
  .o-banner {
    &_outSide {
      background: $menu-linear;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: rem(16) rem(5);

      @include tablet-down {
        background: transparent;
        bottom: rem(40);
        left: 50%;
        padding: 0;
        position: absolute;
        transform: translateX(-50%);
        width: 100%;
      }

      &_btnLeft,
      &_btnRight {
        flex-shrink: 0;
        max-width: rem(298);
        width: 100%;
      }

      &_btnRight {
        margin-left: rem(32);

        @include tablet-down {
          margin: rem(24) 0 0;
        }
      }

      .a-button {
        span {
          @include font-base(18, 24);
          text-transform: uppercase;
          white-space: nowrap;
        }
      }
    }
  }

  &_info {
    background: linear-gradient(0deg, #fff 14.53%, #ccc 42.18%, #fff 73.18%, #ccc 100%);

    .t-showInfo {
      padding: rem(47) 0;
    }
  }

  &_listAward {
    background: url('~assets/images/bg-welcome.jpg') no-repeat center center;
    background-size: cover;
    overflow: hidden;
    padding: rem(40) 0 rem(34);
    position: relative;

    @include tablet-down {
      background: $egyptian-blue-linear;
    }

    &_heading {
      margin: auto;
      max-width: rem(388);

      @include mobile-down {
        max-width: rem(308);
      }

      span {
        white-space: nowrap;
      }
    }

    &_list {
      @include tablet-down {
        margin-top: rem(6);
      }
    }

    &_item {
      margin-top: rem(35);

      @include tablet-down {
        margin-top: rem(24);
      }
    }

    .o-table_header {
      background-color: #e6f3ff;
    }
  }
}